import React, { useEffect } from 'react'
import { Button, Link, Typography } from '@mui/material'
import ReactGA from 'react-ga4'
import { useNavigate } from 'react-router-dom'
import Footer from 'components/Footer'
// import { analytics } from 'index'
import styles from './HomePage.module.scss'

interface HomeScreenProps {}

const Home: React.FC<HomeScreenProps> = ({}) => {
  let navigate = useNavigate()
  const handleEmailClick = () => {
    const email = 'support@getkiro.com'
    window.location.href = `mailto:${email}`
  }

  useEffect(() => {
    ReactGA.event('page_view', {
      page_title: 'home',
      page_location: '/',
    })
  }, [])

  return (
    <div className={styles.homePage}>
      <div className={styles.container}>
        <div className={styles.heroSection}>
          <img src={'images/kiro.svg'} alt="logo" className={styles.logo} />
          <Typography variant="h5" className={styles.text}>
            /MODERN
          </Typography>
          <Typography variant="h5" className={styles.text}>
            /CONVENIENT
          </Typography>
          <Typography variant="h5" style={{ marginBottom: '4px' }} className={styles.text}>
            /ADJUSTMENTS
          </Typography>
        </div>

        {/* <Footer
          buttonText={'Get Adjusted'}
          isLoading={false}
          trackerIndex={10}
          isDisabled={false}
          buttonType={'button'}
          onButtonClick={() => {
            navigate('/login')
          }}
        ></Footer> */}
      </div>
      <div
        style={{
          position: 'absolute',
          bottom: 30,
          height: '90px',
          width: '100%',
          fontWeight: 700,
        }}
        className={styles.footer}
      >
        <Button
          className={styles.button}
          type={'button'}
          onClick={() => {
            navigate('/login')
          }}
          children={'Get Adjusted'}
        ></Button>
        <div
          style={{
            marginTop: '15px',
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: '#050624;',
          }}
        >
          <span
            onClick={() => {
              handleEmailClick()
            }}
            style={{ cursor: 'pointer', fontSize: '16px', color: 'white' }}
          >
            Support
          </span>
        </div>
      </div>
    </div>
  )
}

export default Home
