import React, { useEffect, useState } from 'react'
import { Button, CircularProgress } from '@mui/material'
// import { analytics } from 'index'
import ReactGA from 'react-ga4'
import { useParams } from 'react-router-dom'
import styles from './Download.module.scss'

enum Device_Type {
  ios = 'IOS',
  android = 'Android',
  desktop = 'Desktop',
}
const Download = () => {
  const { id } = useParams()
  const [device, setDevice] = useState('')
  const [isRedirected, setIsRedirected] = useState(false)

  useEffect(() => {
    const userAgent = navigator.userAgent
    const isMobile = /iPhone|iPad|iPod|Android/i.test(userAgent)
    if (isMobile) {
      if (/iPad/i.test(userAgent)) {
        setDevice(Device_Type.ios)
        toAppStore()
      } else if (/iPhone/i.test(userAgent)) {
        setDevice(Device_Type.ios)
        toAppStore()
      } else if (/Android/i.test(userAgent)) {
        setDevice(Device_Type.android)
        toPlayStore()
      }
    } else {
      setDevice(Device_Type.desktop)
    }

    return () => {}
  }, [])

  useEffect(() => {
    ReactGA.event('page_view', {
      page_title: 'Offers|Download',
      page_location: `/offers/${id}/download`,
    })
  }, [])

  const toAppStore = () => {
    // window.location.href = `https://apps.apple.com/us/app/kiro-chiropractic-adjustments/id6475482372`
    window.open('https://apps.apple.com/us/app/kiro-chiropractic-adjustments/id6475482372', '_blank')
    setTimeout(() => {
      setIsRedirected(true)
    }, 1000)
  }

  const toPlayStore = () => {
    // window.location.href = `https://play.google.com/store/apps/details?id=com.getkiro.kiro`
    window.open('https://play.google.com/store/apps/details?id=com.getkiro.kiro', '_blank')
    setTimeout(() => {
      setIsRedirected(true)
    }, 1000)
  }

  if (device === Device_Type.desktop) {
    return (
      <div className={styles.container}>
        <div className={styles.userDetails}>
          <div className={styles.bodyContainer}>
            <div className={styles.header}>
              <img style={{ width: '89px', height: '29px', marginBottom: '20px' }} src={'/images/kiro-logo.svg'}></img>
              <span>Download the app</span>
            </div>
            <div>
              {device === Device_Type.desktop && (
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'center',
                    gap: '18px',
                    flexDirection: 'column',
                  }}
                >
                  <Button
                    className={styles.appStoreButton}
                    sx={{ width: '100%', height: '42px', bordeRadius: ' 6px' }}
                    onClick={toAppStore}
                  >
                    <img src={'/images/app-store.svg'} style={{ marginRight: '5px' }}></img>
                    App Store
                  </Button>

                  <Button
                    className={styles.appStoreButton}
                    sx={{ width: '100%', height: '42px', bordeRadius: '6px' }}
                    onClick={toPlayStore}
                  >
                    <img src={'/images/play-store.svg'} style={{ marginRight: '8px' }}></img>
                    Google Play
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className={styles.container}>
        {!isRedirected && (
          <div className={styles.circularProgress}>
            <CircularProgress
              size={40}
              sx={{
                color: '#007F7C',
                width: '14px',
                height: '14px',
              }}
            />
          </div>
        )}
        {isRedirected && (
          <div className={styles.userDetails}>
            <div className={styles.bodyContainer}>
              <div className={styles.header}>
                <img
                  style={{ width: '89px', height: '29px', marginBottom: '20px' }}
                  src={'/images/kiro-logo.svg'}
                ></img>
                <span>Download the app</span>
              </div>
              <div>
                <div>
                  {device === Device_Type.android && (
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                      <Button
                        className={styles.appStoreButton}
                        sx={{ width: '50%', height: '42px', bordeRadius: '6px' }}
                        onClick={toPlayStore}
                      >
                        <img src={'/images/play-store.svg'} style={{ marginRight: '8px' }}></img>
                        Google Play
                      </Button>
                    </div>
                  )}
                  {device === Device_Type.ios && (
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                      <Button
                        className={styles.appStoreButton}
                        sx={{ width: '50%', height: '42px', bordeRadius: ' 6px' }}
                        onClick={toAppStore}
                      >
                        <img src={'/images/app-store.svg'} style={{ marginRight: '5px' }}></img>
                        App Store
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default Download
