import { useEffect, useState } from 'react'
import { format } from 'date-fns'
import ReactGA from 'react-ga4'
import { useNavigate } from 'react-router-dom'
import Footer from 'components/Footer'
import Header from 'components/Header'
import { SetAvailablePlans, SetSelectedPlan, SetSelectedPlanInfo, SetSelectedSlot } from 'redux/persist'
import { useAppDispatch, useAppSelector } from 'redux/store'
import styles from './Congrats.module.scss'

enum Device_Type {
  ios = 'IOS',
  android = 'Android',
  desktop = 'Desktop',
}

const Congrats = () => {
  const navigate = useNavigate()
  const appointmentTime = useAppSelector((state) => state.selectedSlot.selectedSlot)
  const dispatch = useAppDispatch()
  const [device, setDevice] = useState('')

  useEffect(() => {
    const userAgent = navigator.userAgent

    const isMobile = /iPhone|iPad|iPod|Android/i.test(userAgent)

    if (isMobile) {
      if (/iPad/i.test(userAgent)) {
        setDevice(Device_Type.ios)
      } else if (/iPhone/i.test(userAgent)) {
        setDevice(Device_Type.ios)
      } else if (/Android/i.test(userAgent)) {
        setDevice(Device_Type.android)
      }
    } else {
      setDevice(Device_Type.desktop)
    }
  }, [])

  const toAppStore = () => {
    window.location.href = `https://apps.apple.com/us/app/kiro-chiropractic-adjustments/id6475482372`
  }

  const toPlayStore = () => {
    window.location.href = `https://play.google.com/store/apps/details?id=com.getkiro.kiro`
  }

  useEffect(() => {
    ReactGA.event('page_view', {
      page_title: 'Welcome',
      page_location: '/welcome',
    })
  }, [])

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <div style={{ display: 'flex', flexDirection: 'column', position: 'absolute', left: '18px', top: '13px' }}>
            <span>{'Congrats!'}</span>
            <span>{`You're booked!`}</span>
          </div>
          <img src={'/logo2.png'} alt="logo" className={styles.logo} />
        </div>
        <div className={styles.locationContainer}>
          <div className={styles.appointmentTime}>
            {appointmentTime
              ? format(new Date(appointmentTime?.scheduleObject.appointmentTime), "EEE, MMM dd, yyyy 'at' h:mm a")
              : ''}
          </div>
          <div className={styles.locationDetails}>
            <div>
              <div className={styles.locationText}>KIRO Studio/1 in NoHo </div>
              <div className={styles.locationText}>302 Mott Street </div>
              <div className={styles.locationText}>New York, NY 10012</div>
            </div>
            <div className={styles.mapImage}>
              <img
                style={{ width: '93px', height: '73px', cursor: 'pointer' }}
                src={'/images/mapImage.png'}
                alt="map"
                onClick={() => {
                  //   window.open(
                  //     'https://www.google.com/maps/place/302+Mott+St,+New+York,+NY+10012,+USA/@40.7248592,-73.9940347',
                  //     '_blank',
                  //   )
                  window.location.href =
                    'https://www.google.com/maps/place/KIRO/@40.7248864,-73.996436,16z/data=!3m1!4b1!4m6!3m5!1s0x89c259f7e55d3a41:0x5a2269694f5fbb88!8m2!3d40.7248864!4d-73.9938611!16s%2Fg%2F11vyhmf57s?entry=ttu'
                }}
              />
            </div>
          </div>
        </div>
        <div style={{ color: 'white', position: 'absolute', bottom: '100px' }}>
          Please download the KIRO app below. You will need it to check in to your appointment.
        </div>
        <Footer
          buttonText={'Download KIRO'}
          isLoading={false}
          trackerIndex={5}
          shouldShowFive={true}
          isDisabled={false}
          buttonType={'button'}
          onButtonClick={() => {
            if (device === Device_Type.android) {
              toPlayStore()
            } else if (device === Device_Type.ios) {
              toAppStore()
            } else {
              navigate('/download')
            }
          }}
        ></Footer>
      </div>
    </>
  )
}

export default Congrats
