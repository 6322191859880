import { Offers_Flow_Action_Type } from 'AppConstants/AppConstants'

export interface ITime {
  index: number
  value: string
}

//Selected Slot Persist
interface IScheduleInitialValues {
  scheduleObject: {
    appointmentTime: string
    timezone: string
  }
  index?: number | null
  value?: ITime | null
  date?: any
}
interface SelectedSlotOfferFlowState {
  selectedSlotOfferFlow: IScheduleInitialValues | null
}
const selectedSlotOfferFlowState: SelectedSlotOfferFlowState = {
  selectedSlotOfferFlow: null,
}

export const SetSelectedSlotOfferFlow = (selectedSlotOfferFlow: IScheduleInitialValues | null) => ({
  type: Offers_Flow_Action_Type.SELECTED_SLOT_OFFERS_FLOW,
  payload: selectedSlotOfferFlow,
})

export const selectedSlotOfferFlowPersist = (
  state = selectedSlotOfferFlowState,
  action: { type: Offers_Flow_Action_Type; payload: any },
) => {
  switch (action.type) {
    case Offers_Flow_Action_Type.SELECTED_SLOT_OFFERS_FLOW:
      return {
        ...state,
        selectedSlotOfferFlow: action.payload as IScheduleInitialValues,
      }
    default:
      return state
  }
}
