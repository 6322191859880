import React, { useEffect, useState } from 'react'
import { Button, Typography } from '@mui/material'
import ReactGA from 'react-ga4'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
// import { analytics } from 'index'
import styles from './EmailVerification.module.scss'

enum Device_Type {
  ios = 'IOS',
  android = 'Android',
  desktop = 'Desktop',
}

const EmailVerification = () => {
  const navigate = useNavigate()
  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const status = params.get('status')
  const source = params.get('source')
  const [device, setDevice] = useState('')

  useEffect(() => {
    const userAgent = navigator.userAgent

    const isMobile = /iPhone|iPad|iPod|Android/i.test(userAgent)

    if (isMobile) {
      if (/iPad/i.test(userAgent)) {
        setDevice(Device_Type.ios)
      } else if (/iPhone/i.test(userAgent)) {
        setDevice(Device_Type.ios)
      } else if (/Android/i.test(userAgent)) {
        setDevice(Device_Type.android)
      }
    } else {
      setDevice(Device_Type.desktop)
    }
  }, [])

  const handleEmailClick = () => {
    const email = 'support@getkiro.com'
    window.location.href = `mailto:${email}`
  }

  const toAppStore = () => {
    window.location.href = `https://apps.apple.com/us/app/kiro-chiropractic-adjustments/id6475482372`
  }

  const toPlayStore = () => {
    window.location.href = `https://play.google.com/store/apps/details?id=com.getkiro.kiro`
  }

  // useEffect(() => {
  //   analytics.page()
  // }, [])

  useEffect(() => {
    ReactGA.event('page_view', {
      page_title: 'Email Verification',
      page_location: '/email-verification',
    })
  }, [])

  if (status === 'success') {
    return (
      <div className={styles.container}>
        <div className={styles.emailPage}>
          <div className={styles.content}>
            <div className={styles.header}>
              <img style={{ width: '89px', height: '29px' }} src={'images/kiro-logo.svg'} alt="kiro logo"></img>
              <img style={{ width: '30px', height: '30px' }} src={'images/emailSuccess.svg'} alt="email success"></img>
            </div>
            <Typography variant="h5">Email Verified</Typography>
          </div>
          {source === 'web' && (
            <div>
              <div className={styles.descriptionContainer}>
                <div className={styles.description}>
                  Complete your onboarding and schedule your first adjustment through the KIRO mobile app.
                </div>
              </div>

              {device === Device_Type.desktop && (
                <div style={{ display: 'flex', width: '100%', justifyContent: 'center', gap: '12px' }}>
                  <Button
                    className={styles.appStoreButton}
                    sx={{ width: '100%', height: '48px', bordeRadius: ' 6px' }}
                    onClick={toAppStore}
                  >
                    <img src={'images/app-store.svg'} style={{ marginRight: '5px' }}></img>
                    App Store
                  </Button>

                  <Button
                    className={styles.appStoreButton}
                    sx={{ width: '100%', height: '48px', bordeRadius: '6px' }}
                    onClick={toPlayStore}
                  >
                    <img src={'images/play-store.svg'} style={{ marginRight: '8px' }}></img>
                    Google Play
                  </Button>
                </div>
              )}
              {device === Device_Type.android && (
                <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                  <Button
                    className={styles.appStoreButton}
                    sx={{ width: '50%', height: '48px', bordeRadius: '6px' }}
                    onClick={toPlayStore}
                  >
                    <img src={'images/play-store.svg'} style={{ marginRight: '8px' }}></img>
                    Google Play
                  </Button>
                </div>
              )}

              {device === Device_Type.ios && (
                <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                  <Button
                    className={styles.appStoreButton}
                    sx={{ width: '50%', height: '48px', bordeRadius: ' 6px' }}
                    onClick={toAppStore}
                  >
                    <img src={'images/app-store.svg'} style={{ marginRight: '5px' }}></img>
                    Apple Store
                  </Button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    )
  } else {
    return (
      <div className={styles.container}>
        <div className={styles.emailPage}>
          <div className={styles.content}>
            <div className={styles.header}>
              <img src={'images/emailFailure.svg'} alt="email failed"></img>
            </div>
            <Typography variant="h5">Verification failed</Typography>
            <div className={styles.link}>
              Need any help?{' '}
              <span
                onClick={() => {
                  handleEmailClick()
                }}
                style={{ marginLeft: '5px' }}
              >
                support@getkiro.com
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default EmailVerification
