import style from './Header.module.scss'
//import { default as Logo } from '../images/kiro-logo.svg'

interface HeaderProps {
  headerText: string
  showBackButton: boolean
  onBack?: () => void
  hideIcon?: boolean
}

export default function Header({ headerText, showBackButton, onBack, hideIcon }: HeaderProps) {
  return (
    <div className={style.header}>
      {showBackButton && (
        <div onClick={onBack} style={{ cursor: 'pointer', marginTop: '0.3rem' }}>
          <img src={'/images/backArrow.svg'} alt="arrow"></img>
        </div>
      )}
      <span style={{ flex: 1, marginLeft: '10px' }}>{headerText}</span>
      <img src={'/logo2.png'} alt="logo" className={style.logo} style={{ display: hideIcon ? 'none' : '' }} />
    </div>
  )
}
