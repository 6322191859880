import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist'
import storage from 'redux-persist/lib/storage/session'
import authReducer from 'redux/auth'
import { selectedSlotOfferFlowPersist } from 'redux/Offers/persist'
import {
  availablePlanPersist,
  selectedPlanInfoPersist,
  selectedPlanPersist,
  selectedSlotPersist,
  slotBookedPersist,
  paymentDonePersist,
} from 'redux/persist'
import { selectedSlotReferralFlowPersist } from 'redux/Referral/persist'
import waiverReducer from 'redux/waiver'

const persistConfig = {
  key: 'root',
  storage,
  // Only allow persistence for reducer
  whitelist: [
    'availablePlan',
    'selectedPlan',
    'selectedSlot',
    'selectedPlanInfo',
    'slotBooked',
    'payment',
    'selectedSlotReferral',
    'slotBookedAlreadyReferral',
    'selectedSlotOffer',
  ],
}

const rootReducer = combineReducers({
  auth: authReducer,
  availablePlan: availablePlanPersist,
  selectedPlan: selectedPlanPersist,
  selectedSlot: selectedSlotPersist,
  selectedPlanInfo: selectedPlanInfoPersist,
  slotBooked: slotBookedPersist,
  waiver: waiverReducer,
  payment: paymentDonePersist,
  // referralFlow
  selectedSlotReferral: selectedSlotReferralFlowPersist,
  // slotBookedAlreadyReferral: slotBookedReferralFlowPersist,
  // offersFlow
  selectedSlotOffer: selectedSlotOfferFlowPersist,
})

export type RootState = ReturnType<typeof rootReducer>

const persistedRootReducer = persistReducer(persistConfig, rootReducer)
const store = configureStore({
  reducer: persistedRootReducer,
  devTools: true,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
})

export type AppDispatch = typeof store.dispatch

export const persister = persistStore(store)
export default store

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
