import React, { ReactElement } from 'react'
import { TextField as TextInputField, BaseTextFieldProps } from '@mui/material'

interface TextInputProps extends BaseTextFieldProps {
  type?: string
  className?: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
}

const TextField: React.FC<TextInputProps> = ({
  type = 'text',
  className = '',
  variant = 'outlined',
  onChange = () => null,
  ...props
}): ReactElement => {
  return <TextInputField {...props} type={type} variant="outlined" autoComplete="off" onChange={onChange} />
}

export default TextField
