import React, { useEffect, useState } from 'react'
import { Grid, Typography, Button, TextField, Checkbox } from '@mui/material'
import axios from 'axios'
import ReactGA from 'react-ga4'
import PhoneInput from 'react-phone-input-2'
import { useLocation, useNavigate } from 'react-router-dom'
import { persistStore } from 'redux-persist'
import { AppConstants } from 'AppConstants/AppConstants'
import Header from 'components/Header'
// import TextField from 'components/shared/textField'
// import { loginVerifyCodeAsync, signInAsync } from 'redux/auth'
// import { useAppDispatch } from 'redux/store'
// import { analytics } from 'index'
import {
  SetAvailablePlans,
  SetPaymentDone,
  SetSelectedPlan,
  SetSelectedPlanInfo,
  SetSelectedSlot,
  SetSlotBookedAlready,
} from 'redux/persist'
import store, { useAppDispatch } from 'redux/store'
import { StatusEnum } from 'redux/types'
import { decodeToken } from 'utils/decodeToken'
import styles from './Login.module.scss'
import useAuth from '../../../hooks/useAuth'

const Login = () => {
  const [phoneNumber, setPhoneNumber] = useState('')
  const [formattedPhoneNum, setFormattedPhoneNum] = useState('')
  const [errorType, setErrorType] = useState('')
  const [otpError, setOtpError] = useState(false)
  const [OTPScreen, setOTPScreen] = useState(false)
  const [disableButton, setDisableButton] = useState(true)
  const [isChecked, setIsChecked] = useState(true)
  const [disableVerifyBtn, setDisableVerifyBtn] = useState(true)
  const [code, setCode] = useState('')
  const [verifyStatus, setVerifyStatus] = useState<StatusEnum>(StatusEnum.Idle)
  const dispatch = useAppDispatch()
  const persistor = persistStore(store)
  const navigate = useNavigate()
  // const [phoneInfo, setPhoneInfo]: any = useState({
  //   countryCode: '',
  //   dialCode: '',
  //   phoneLength: 10,
  // })

  useEffect(() => {
    if (verifyStatus === StatusEnum.Success) {
      let localToken = localStorage.getItem(AppConstants.ACCESS_TOKEN)
      if (localToken) {
        const isPatient = decodeToken(localToken)
        if (isPatient && isPatient === 'PATIENT') {
          getProfile()
        } else {
          navigate('/signup')
        }
      }
      // if (currentRole) {
      //   if (currentRole === 'PATIENT') {
      //     navigate('/add-photo')
      //   } else {
      //     navigate('/signup')
      //   }
      // }
    }
  }, [verifyStatus])

  // useEffect(() => {
  //   analytics.page()
  // }, [])

  useEffect(() => {
    ReactGA.event('page_view', {
      page_title: 'login',
      page_location: '/login',
    })
  }, [])

  const handleCheckBoxChange = (e: any) => {
    setIsChecked(e.target.checked)
    if (e.target.checked === true && phoneNumber.length > 4) {
      setDisableButton(false)
    } else {
      setDisableButton(true)
    }
  }

  useEffect(() => {
    dispatch(SetAvailablePlans([]))
    dispatch(SetSelectedPlan(null))
    dispatch(SetSelectedPlanInfo(null))
    dispatch(SetSelectedSlot(null))
    dispatch(SetSlotBookedAlready(false))
    dispatch(SetPaymentDone(false))
  }, [])

  const sendOtp = async () => {
    if (phoneNumber.length >= 4) {
      setDisableButton(true)
      const response: any = await axios
        .post(AppConstants.API_URL + '/auth/patient/login', {
          phone: '+' + phoneNumber.replace(/-/g, ''),
        })
        .then((res) => res)
        .catch((err) => err.response)
      if (
        response &&
        response?.data?.code === 'success' &&
        response?.data?.message === 'Verification code sent successfully'
      ) {
        setDisableButton(false)
        setOTPScreen(true)
      } else if (response && response?.data?.message === 'Phone number is not found') {
        setErrorType('Not_User')
        setDisableButton(false)
      } else if (response && response?.data.message === 'Unable to send verification code') {
        setErrorType('formatInvalid')
        setDisableButton(false)
      } else if (response && response.data && response.data.code >= 400 && response.data.code <= 599) {
        setErrorType('Error_Occurred')
        setDisableButton(false)
      } else if (response && response.data && response.data.statusCode >= 400 && response.data.statusCode <= 599) {
        setErrorType('Error_Occurred')
        setDisableButton(false)
      }
    } else {
      setErrorType('formatInvalid')
      setDisableButton(false)
    }
  }

  const onVerify = async () => {
    setDisableButton(true)

    if (code === '') {
      setOtpError(true)
      setErrorType('Empty')
      setDisableButton(false)
    } else {
      try {
        console.log('payload', {
          phone: '+' + phoneNumber.replace(/-/g, ''),
          code: code,
          signupThrough: 'web',
        })
        const response: any = await axios
          .post(AppConstants.API_URL + '/auth/patient/login/verify', {
            phone: '+' + phoneNumber.replace(/-/g, ''),
            code: code,
            signupThrough: 'web',
          })
          .then((res) => res)
          .catch((err) => err.response)
        console.log(response, 'total response')

        if (response && response.data && response.data.code === 'success') {
          ReactGA.event('login', {
            method: 'web',
          })
          localStorage.setItem(AppConstants.ACCESS_TOKEN, response?.data?.data?.accessToken)
          let localToken = response.data?.data?.accessToken
          let tokenParts = localToken.split('.')

          let tokenPayload = tokenParts[1]
          let tokenPayloadStr = atob(tokenPayload)

          let decodedToken: any = JSON.parse(tokenPayloadStr)

          let tempCurrentRole: any = ''
          if (decodedToken) {
            if (decodedToken.roles && decodedToken.roles.length === 1) {
              tempCurrentRole = decodedToken.roles[0]
            }
          } else {
            tempCurrentRole = null
          }
          localStorage.setItem(AppConstants.CURRENTROLE_TOKEN, tempCurrentRole)
          // if (tempCurrentRole === 'PATIENT') {
          //   navigate('/add-photo')
          // } else {
          //   navigate('/signup')
          // }
          setVerifyStatus(StatusEnum.Success)
        } else if (response && response.data && response.data.code === 'invalid_value') {
          setOtpError(true)
          setErrorType('Invalid')
          //   setVerifyStatus(StatusEnum.Failed)
        } else if (response && response.data && response.data.code === 'code_expired') {
          setOtpError(true)
          setErrorType('Code_Expired')
          //   setVerifyStatus(StatusEnum.Failed)
        } else if (response && response.data && response.data.code === 'user_not_found') {
          setErrorType('Not_User')
          //   setVerifyStatus(StatusEnum.Failed)
        } else if (response && response.data && response.data.statusCode >= 400 && response.data.statusCode <= 599) {
          setErrorType('Error_Occurred')
          //   setVerifyStatus(StatusEnum.Failed)
        }
      } catch (error) {
        console.error('An error occurred:', error)
      }
    }
  }

  async function getProfile() {
    try {
      const response: any = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/auth/me`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          'x-platform-id': process.env.REACT_APP_PLATFORM_ID || '',
        },
      })
      if (response && response?.data.code === 'success' && !response?.data?.data?.initialPayment) {
        navigate('/choose-plan')
      }
      // if (response && response?.data.code === 'success' && !response?.data?.data?.profilPic) {
      //   navigate('/add-photo')
      // }
      //  else if (
      //   response &&
      //   response?.data.code === 'success' &&
      //   response?.data?.data?.profilPic &&
      //   !response?.data?.data?.initialWaiver
      // ) {
      //   navigate('/waiver-forms')
      // }
      // else if (
      //   response &&
      //   response?.data.code === 'success' &&
      //   response?.data?.data?.profilPic &&
      //   // response?.data?.data?.initialWaiver &&
      //   !response?.data?.data?.initialPayment
      // ) {
      //   navigate('/choose-plan')
      // }
      // else if (
      //   response &&
      //   response?.data.code === 'success' &&
      //   response?.data?.data?.profilPic &&
      //   response?.data?.data?.initialPayment &&
      //   (response?.data?.data?.patient?.screeningStatus === 'not_booked' || !response?.data?.data?.initialWaiver)
      // ) {
      //   navigate('/introductory-screening')
      // }
      else {
        navigate('/download')
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (phoneNumber.length >= 4) {
      setDisableButton(false)
    }
  }, [OTPScreen])

  if (!OTPScreen) {
    return (
      <div className={styles.container}>
        <Header headerText="" showBackButton={false}></Header>
        <div className={styles.loginDetail}>
          <div>
            {/* <div style={{ marginBottom: '3rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <img src="images/kiroTextLogo.svg" alt="kiro logo" />
            </div> */}
            <form>
              <Grid item xs={12}>
                <Grid item xs={12}>
                  {/* <TextField
                    autoFocus
                    inputProps={{
                      inputMode: 'numeric',
                      pattern: '[0-9]*',
                      maxLength: 12,
                    }}
                    sx={
                      errorType === 'fieldEmpty' || errorType === 'formatInvalid' || errorType === 'userNot'
                        ? {
                            backgroundImage: "url('images/error.svg')",
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'right 15px center',
                            textTransform: 'capitalize',
                          }
                        : {
                            '& .MuiInputBase-input': {
                              textTransform: 'capitalize',
                            },
                          }
                    }
                    placeholder="Enter Phone Number"
                    value={phoneNumber}
                    label="Phone Number"
                    name="phone"
                    error={errorType !== '' ? true : false}
                    onChange={(e) => {
                      const formattedValue = e.target.value
                        .replace(/\D/g, '')
                        .slice(0, 10)
                        .replace(/(\d{3})(\d{1,3})?(\d{0,4})?/, (_, first, second, third) => {
                          let result = first
                          if (second) result += '-' + second
                          if (third) result += '-' + third
                          return result
                        })
                      setErrorType('')
                      setPhoneNumber(formattedValue)
                      if (formattedValue.length === 12) {
                        setDisableButton(false)
                      } else {
                        setDisableButton(true)
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault()
                        if (!disableButton) {
                          sendOtp()
                        }
                      }
                    }}
                  /> */}

                  <PhoneInput
                    inputProps={{
                      name: 'phone',
                      autoFocus: true,
                    }}
                    // enableSearch
                    // searchStyle={{ width: 250, height: 20, border: 'none' }}
                    country={'us'}
                    value={phoneNumber}
                    preferredCountries={['us', 'ca', 'gb', 'sa', 'ae']}
                    priority={['us', 'ca', 'gb', 'sa', 'ae']}
                    preserveOrder={['preferredCountries']}
                    // onlyCountries={['us', 'in', 'ca', 'uk', 'sa', 'ae']}
                    containerClass={styles.phoneCtn}
                    dropdownClass={styles.dropdown}
                    specialLabel=""
                    inputClass={styles.input}
                    buttonClass={styles.countryBtn}
                    countryCodeEditable={false}
                    // dropdownStyle={{ width: '360px' }}
                    // masks={{
                    //   us: '...-...-....',
                    //   in: '...-...-....',
                    //   ca: '...-...-....',
                    //   uk: '...-...-....',
                    //   sa: '...-...-...-...',
                    //   ae: '...-...-...-...',
                    // }}
                    // priority={{ us: 1, in: 0, ca: 0, uk: 0, sa: 0, ae: 0 }}
                    onChange={(value, e: any) => {
                      setPhoneNumber(value)
                      setErrorType('')
                      const formatVal = value.slice(e.dialCode.length)
                      setFormattedPhoneNum(formatVal)
                      if (formatVal.length < 4) {
                        setDisableButton(true)
                      } else {
                        setDisableButton(false)
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault()
                        if (!disableButton && isChecked) {
                          sendOtp()
                        }
                      }
                    }}
                    inputStyle={
                      errorType === 'fieldEmpty' || errorType === 'formatInvalid' || errorType === 'userNot'
                        ? {
                            backgroundImage: "url('images/error.svg')",
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'right 15px center',
                            textTransform: 'capitalize',
                            border: '2px solid #F24343',
                          }
                        : {}
                    }
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sx={{ marginTop: '130px', position: 'absolute', width: '92%' }}>
                {(errorType === 'fieldEmpty' ||
                  errorType === 'formatInvalid' ||
                  errorType === 'Not_User' ||
                  errorType === 'Error_Occurred') && (
                  <div className={styles.errorContainer}>
                    <Typography sx={{ fontSize: '14px', fontWeight: 700 }}>
                      {errorType === 'fieldEmpty' && `Enter Your Phone Number`}
                      {errorType === 'formatInvalid' && `Invalid Phone Number`}
                      {errorType === 'Not_User' && `User Not Found`}
                      {errorType === 'Error_Occurred' && `Oops! something went wrong , try again later`}
                    </Typography>
                    <div
                      style={{
                        display: 'flex',
                        flex: '1',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        paddingRight: '12px',
                      }}
                    >
                      <img
                        onClick={() => {
                          setErrorType('')
                        }}
                        src={'images/wrong.svg'}
                        style={{ cursor: 'pointer' }}
                      ></img>
                    </div>
                  </div>
                )}
              </Grid>
            </form>

            <Button
              // className={styles.submitBtn}
              id="send-otp"
              type="submit"
              sx={disableButton ? { opacity: '0.5' } : {}}
              children={'Send Passcode'}
              onClick={
                disableButton
                  ? undefined
                  : () => {
                      sendOtp()
                    }
              }
            />
          </div>
          <div className={styles.agreeContainer}>
            {/* <Checkbox
                checked={isChecked}
                onChange={(e) => {
                  handleCheckBoxChange(e)
                }}
                sx={{
                  color: '#007f7c',
                  '&.Mui-checked': {
                    color: '#007f7c',
                  },
                  marginLeft: '-10px',
                }}
                size="medium"
              /> */}
            <div className={styles.agreeText}>
              100% Secure. By Submitting, you agree to KIRO’s Terms of Use and Privacy Policy. You consent to receive
              text messages from us at the mobile number provided to book your appointment and receive reminders.
              Message frequency depends on your activity. Reply STOP to opt-out.
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className={styles.container}>
        <Header
          headerText=""
          showBackButton={true}
          onBack={() => {
            setCode('')
            setOTPScreen(false)
            setErrorType('')
            setOtpError(false)
            setPhoneNumber(phoneNumber)
            setDisableVerifyBtn(true)
          }}
          hideIcon={false}
        />
        <div className={styles.loginDetail}>
          <div>
            <div>
              {/* <div style={{ marginBottom: '3rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img src="images/kiroTextLogo.svg" alt="kiro logo" />
              </div> */}
              <p style={{ color: 'white', marginBottom: 20, lineHeight: 1.6, fontSize: '18px' }}>
                Please enter the 6 digit code sent to <br></br>
                <b>{formattedPhoneNum}</b> through SMS.
              </p>
              <form
                onSubmit={(e) => {
                  e.preventDefault()
                  if (!disableVerifyBtn) {
                    onVerify()
                  }
                }}
              >
                <Grid container spacing={2} sx={{ marginBottom: '2rem' }}>
                  <Grid item xs={12}>
                    <TextField
                      autoFocus
                      inputProps={{
                        inputMode: 'numeric',
                        pattern: '[0-9]*',
                        maxLength: 6,
                      }}
                      sx={
                        otpError
                          ? {
                              backgroundImage: "url('images/error.svg')",
                              backgroundRepeat: 'no-repeat',
                              backgroundPosition: 'right 15px center',
                              textTransform: 'capitalize',
                            }
                          : {}
                      }
                      value={code}
                      placeholder="Enter Code"
                      label="Verification Code"
                      name="code"
                      // type="number"
                      error={otpError}
                      onChange={(e) => {
                        setCode(e.target.value)
                        setOtpError(false)
                        if (e.target.value.length >= 6) {
                          setDisableVerifyBtn(false)
                        } else {
                          setDisableVerifyBtn(true)
                        }
                      }}
                    />
                  </Grid>
                </Grid>
              </form>
              <Grid item xs={12} sx={{ marginTop: '90px', position: 'absolute', width: '92%' }}>
                {otpError && (
                  <div className={styles.errorContainer}>
                    <Typography sx={{ fontSize: '14px', fontWeight: 700 }}>
                      {errorType === 'Empty' && `Please enter passcode`}
                      {errorType === 'Invalid' && `Invalid Code`}
                      {errorType === 'Code_Expired' && `Oops! code expired, Click Resend`}
                      {errorType === 'Error_Occurred' && `Oops! something went wrong`}
                      {errorType === 'Not_User' && `User not found`}
                    </Typography>
                    <div
                      style={{
                        display: 'flex',
                        flex: '1',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        paddingRight: '12px',
                      }}
                    >
                      <img
                        onClick={() => {
                          setOtpError(false)
                        }}
                        src={'images/wrong.svg'}
                        style={{ cursor: 'pointer' }}
                      ></img>
                    </div>
                  </div>
                )}
              </Grid>
            </div>
          </div>
          <div className={styles.btnWrapper}>
            <Button
              id="sign-in-button"
              sx={disableVerifyBtn ? { opacity: '0.5' } : {}}
              type="submit"
              children={'Verify'}
              onClick={
                disableVerifyBtn
                  ? undefined
                  : () => {
                      onVerify()
                    }
              }
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '0.8rem' }}>
            <div
              className={styles.signUpText}
              onClick={() => {
                sendOtp()
              }}
            >
              Resend OTP{'>'}
            </div>
            <div
              className={styles.signUpText}
              onClick={() => {
                setOTPScreen(false)
                setCode('')
                setDisableVerifyBtn(true)
                if (otpError || errorType !== '') {
                  setErrorType('')
                  setOtpError(false)
                }
              }}
            >
              Wrong Number?
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Login
