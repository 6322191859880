import React, { useEffect, useRef, useState } from 'react'
import { Button, CircularProgress } from '@mui/material'
import ReactGA from 'react-ga4'
import { useNavigate } from 'react-router-dom'
// import { analytics } from 'index'
import styles from './intro.module.scss'

const Intro = () => {
  const navigate = useNavigate()

  const videoRef: any = useRef(null)
  const [playing, setPlaying] = useState(false)

  const handlePlayPause = () => {
    if (videoRef.current) {
      if (playing) {
        videoRef.current.pause()
      } else {
        videoRef.current.play()
      }
      setPlaying(!playing)
    } else {
      console.error('Video playback error.')
    }
  }

  // useEffect(() => {
  //   analytics.page()
  // }, [])

  useEffect(() => {
    ReactGA.event('page_view', {
      page_title: 'Intro',
      page_location: '/intro',
    })
  }, [])

  useEffect(() => {
    window.location.href = `https://www.getkiro.com/`
  }, [])

  return (
    <div className={styles.container}>
      {/* <div className={styles.videoContainer}>
        <div>
          <video
            src="https://storage.googleapis.com/kiro-production-49817cb/intro.mp4"
            height="100%"
            width="100%"
            controls={false}
            poster="https://storage.googleapis.com/kiro-production-49817cb/intro-thumbnail.jpeg"
            playsInline
            webkit-playsInline
            controlsList="nodownload"
            ref={videoRef}
            onClick={handlePlayPause}
            onEnded={() => setPlaying(false)}
          ></video>
        </div>
      </div>
      <div className={styles.footer}>
        <Button
          children={'JOIN THE MOVEMENT'}
          type={'button'}
          onClick={() => {
            navigate('/download')
          }}
          style={{ border: '2px solid white', height: '48px', borderRadius: '10px', fontSize: '16px' }}
        ></Button>
      </div> */}
      <div className={styles.circularProgress}>
        <CircularProgress
          size={40}
          sx={{
            color: '#007F7C',
            width: '14px',
            height: '14px',
          }}
        />
      </div>
    </div>
  )
}

export default Intro
