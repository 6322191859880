import { Action, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ApiGet, ApiGetPaymentStatus, ApiPost, ApiPostNoAuth, ApiPostPlanId, ApiPut, ApiVisitPost } from 'api'
import { APIResponse } from 'api/Interface'
import { Action_Type } from 'AppConstants/AppConstants'
import {
  IAuthState,
  IPlan,
  IPlanIdPayload,
  IPlanIdSecret,
  IScheduleAuthPayload,
  ISignUpDataEdit,
  ISignUpPayload,
  IUser,
  IWaiverAuthPayload,
  UserData,
} from 'redux/auth/Interface'
import { RootState } from 'redux/store'
import { StatusEnum } from 'redux/types'

export const initialState: IAuthState = {
  token: '',
  user: null,
  authError: null,
  data: null,
  signUpStatus: StatusEnum.Idle,
  signUpEditApiAuthStatus: StatusEnum.Idle,
  waiverAuthStatus: StatusEnum.Idle,
  scheduleAuthStatus: StatusEnum.Idle,
  waiverAuthData: null,
  purchasePlanAuthStatus: StatusEnum.Idle,
  purchasePlanAuthSecret: null,
  userProfileStatus: StatusEnum.Idle,
  userProfileData: null,
  uploadProfile: null,
  uploadProfileStatus: StatusEnum.Idle,
  platformDetails: null,
  paymentAuthData: null,
  paymentAuthStatus: StatusEnum.Idle,
  plansStatus: StatusEnum.Idle,
  plans: [],
  stripePaymentStatus: StatusEnum.Idle,
  screeningStatus: StatusEnum.Idle,
  screeningAvailabilityData: null,
  verifyCodeStatus: StatusEnum.Idle,
  loginVerifyCodeStatus: StatusEnum.Idle,
  loginStatus: StatusEnum.Idle,
}

export const signUpAsync = createAsyncThunk('auth/signUp', async (data: ISignUpPayload, thunkAPI) => {
  try {
    const response: any = await ApiPostNoAuth('/auth/consumer/signup', data)
    if (response) {
      return thunkAPI.fulfillWithValue(response)
    }
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error)
  }
})

export const signInAsync = createAsyncThunk('auth/signIn', async ({ phone }: { phone: string }, thunkAPI) => {
  try {
    const response: any = await ApiPostNoAuth('/auth/login', { phone })
    if (response) {
      return thunkAPI.fulfillWithValue(response)
    }
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error)
  }
})
export const resendOTPAsync = createAsyncThunk(
  'auth/resendOTPAsync',
  async ({ phone, id }: { phone: string; id: string }, thunkAPI) => {
    try {
      const response: any = await ApiPostNoAuth('/auth/signup/resend-otp', { phone, id })
      if (response) {
        return thunkAPI.fulfillWithValue(response)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const loginResendOTPAsync = createAsyncThunk(
  'auth/loginResendOTPAsync',
  async ({ phone, id }: { phone: string; id: string }, thunkAPI) => {
    try {
      const response: any = await ApiPostNoAuth('/auth/signup/resend-otp', { phone, id })
      if (response) {
        return thunkAPI.fulfillWithValue(response)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const verifyCodeAsync = createAsyncThunk(
  'auth/verifyCode',
  async ({ phone, code }: { phone: string; code: string }, thunkAPI) => {
    try {
      const response: any = await ApiPostNoAuth('/auth/verify/signup-code', { phone, code })
      if (response) {
        return thunkAPI.fulfillWithValue(response)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
export const loginVerifyCodeAsync = createAsyncThunk(
  'auth/loginVerifyCode',
  async ({ phone, code }: { phone: string; code: string }, thunkAPI) => {
    try {
      const response: any = await ApiPostNoAuth('/auth/login/verify', { phone, code })
      if (response) {
        return thunkAPI.fulfillWithValue(response)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const scheduleInitialAsync = createAsyncThunk(
  'auth/scheduleAuthentication',
  async (data: IScheduleAuthPayload, thunkAPI) => {
    const response: APIResponse = await ApiVisitPost('/visit/screening/appointment', data)
      .then((res) => res)
      .catch((err) => err)
    if (response && !response.error) {
      return thunkAPI.fulfillWithValue(response.data)
    } else {
      return thunkAPI.rejectWithValue(response.error)
    }
  },
)

export const signUpEditApi = createAsyncThunk('auth/signUpEditApi', async (data: ISignUpDataEdit, thunkAPI) => {
  const response: any = await ApiPut('/auth/consumer/signup', data)
    .then((res) => res)
    .catch((err) => err)
  if (response && !response.error) {
    return thunkAPI.fulfillWithValue(response)
  } else {
    return thunkAPI.rejectWithValue(response.error)
  }
})

export const acceptWaiverAsync = createAsyncThunk(
  'auth/waiverAuthentication',
  async (data: IWaiverAuthPayload, thunkAPI) => {
    const response: APIResponse = await ApiPost('/patient/waiver-acceptance', data)
      .then((res) => res)
      .catch((err) => err)
    if (response && !response.error) {
      return thunkAPI.fulfillWithValue(response.data)
    } else {
      return thunkAPI.rejectWithValue(response.error)
    }
  },
)

export const getPlansAsync = createAsyncThunk('auth/getPlansAsync', async (_, thunkAPI) => {
  const response: APIResponse = await ApiGet('/payment-plan')
    .then((res) => res)
    .catch((err) => err)

  if (response && !response.error) {
    return thunkAPI.fulfillWithValue(response.data)
  } else {
    return thunkAPI.rejectWithValue(response.error)
  }
})

export const getScreeningAvailabilityData = createAsyncThunk(
  'auth/getScreeingAvailability',
  async (selectedDate: string, thunkAPI) => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const response: APIResponse = await ApiGet(`/visit/screener/availability?date=${selectedDate}&timezone=${timeZone}`)
      .then((res) => res)
      .catch((err) => err)

    if (response && !response.error) {
      return thunkAPI.fulfillWithValue(response.data)
    } else {
      return thunkAPI.rejectWithValue(response.error)
    }
  },
)

export const purchasePlanAsync = createAsyncThunk('auth/postPlanIdAsync', async (data: IPlanIdPayload, thunkAPI) => {
  const response: APIResponse = await ApiPostPlanId('/payment-plan/purchase', data)
    .then((res) => res)
    .catch((err) => err)

  if (response && !response.error && response.status === 201) {
    return thunkAPI.fulfillWithValue(response.data)
  } else {
    return thunkAPI.rejectWithValue(response.error)
  }
})

export const getProfileAsync = createAsyncThunk('auth/getProfileAsync', async (_, thunkAPI) => {
  const response: APIResponse = await ApiGet('/auth/me')
    .then((res) => res)
    .catch((err) => err)

  if (response && !response.error) {
    return thunkAPI.fulfillWithValue(response.data)
  } else {
    return thunkAPI.rejectWithValue(response.error)
  }
})

export const getPaymentAsync = createAsyncThunk('auth/getPaymentAsync', async (planId: string, thunkAPI) => {
  const response: APIResponse = await ApiGetPaymentStatus(`/payment-plan/${planId}`)
    .then((res) => res)
    .catch((err) => err)

  if (response && !response.error) {
    return thunkAPI.fulfillWithValue(response.data)
  } else {
    return thunkAPI.rejectWithValue(response.error)
  }
})

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetLogin: (state) => {
      state.token = ''
      state.user = null
      state.authError = null
      state.userProfileStatus = StatusEnum.Idle
      localStorage.clear()
      sessionStorage.clear()
    },
    userData: (state, action) => {
      state.user = action.payload
    },
    signUpData: (state, action) => {
      state.data = action.payload
    },
    updateStripePaymentStatus: (state, action) => {
      state.stripePaymentStatus = action.payload.status
    },
    resetPaymentData: (state, action) => {
      state.stripePaymentStatus = StatusEnum.Idle
      state.purchasePlanAuthStatus = StatusEnum.Idle
      state.paymentAuthData = null
    },
  },
  extraReducers: (builder) => {
    // Register
    builder.addCase(signUpAsync.pending, (state) => {
      state.signUpStatus = StatusEnum.Pending
    })
    builder.addCase(signUpAsync.fulfilled, (state, action) => {
      state.signUpStatus = StatusEnum.Success
      const actionPayload = action.payload.data.data as { token: string; user: UserData }
      console.log(actionPayload, 'signup payload')

      state.token = actionPayload?.token
      state.user = actionPayload?.user
    })
    builder.addCase(signUpAsync.rejected, (state) => {
      state.signUpStatus = StatusEnum.Failed
    })

    //Login
    builder.addCase(signInAsync.pending, (state) => {
      state.loginStatus = StatusEnum.Pending
    })
    builder.addCase(signInAsync.fulfilled, (state, action) => {
      state.loginStatus = StatusEnum.Success
    })
    builder.addCase(signInAsync.rejected, (state) => {
      state.loginStatus = StatusEnum.Failed
    })

    // Waiver Authentication
    builder.addCase(acceptWaiverAsync.pending, (state) => {
      state.waiverAuthStatus = StatusEnum.Pending
    })
    builder.addCase(acceptWaiverAsync.fulfilled, (state, action) => {
      // state.waiverAuthData = action.payload as IWaiverAuthResponse;
      state.waiverAuthStatus = StatusEnum.Success
    })
    builder.addCase(acceptWaiverAsync.rejected, (state) => {
      state.waiverAuthStatus = StatusEnum.Failed
    })

    //Get Profile
    builder.addCase(getProfileAsync.pending, (state) => {
      state.userProfileStatus = StatusEnum.Pending
    })
    builder.addCase(getProfileAsync.fulfilled, (state, action) => {
      state.userProfileStatus = StatusEnum.Success
      state.userProfileData = action.payload as IUser
    })
    builder.addCase(getProfileAsync.rejected, (state) => {
      state.userProfileStatus = StatusEnum.Failed
    })

    //Post Plan Id
    builder.addCase(purchasePlanAsync.pending, (state) => {
      state.purchasePlanAuthStatus = StatusEnum.Pending
    })
    builder.addCase(purchasePlanAsync.fulfilled, (state, action) => {
      state.purchasePlanAuthStatus = StatusEnum.Success
      state.purchasePlanAuthSecret = action.payload.data as IPlanIdSecret
    })
    builder.addCase(purchasePlanAsync.rejected, (state) => {
      state.purchasePlanAuthStatus = StatusEnum.Failed
    })

    //Get Payment Status

    builder.addCase(getPaymentAsync.pending, (state) => {
      state.paymentAuthStatus = StatusEnum.Pending
    })
    builder.addCase(getPaymentAsync.fulfilled, (state, action) => {
      state.paymentAuthStatus = StatusEnum.Success
      state.paymentAuthData = action.payload.data
      if (action.payload.status === 'success') {
        state.stripePaymentStatus = StatusEnum.Idle
      }
    })
    builder.addCase(getPaymentAsync.rejected, (state) => {
      state.paymentAuthStatus = StatusEnum.Failed
    })

    //Post Intital Schedule
    builder.addCase(scheduleInitialAsync.pending, (state) => {
      state.scheduleAuthStatus = StatusEnum.Pending
    })
    builder.addCase(scheduleInitialAsync.fulfilled, (state, action) => {
      state.scheduleAuthStatus = StatusEnum.Success
    })
    builder.addCase(scheduleInitialAsync.rejected, (state) => {
      state.scheduleAuthStatus = StatusEnum.Failed
    })

    // Get Plans
    builder.addCase(getPlansAsync.pending, (state) => {
      state.plansStatus = StatusEnum.Pending
    })
    builder.addCase(getPlansAsync.fulfilled, (state, action) => {
      state.plansStatus = StatusEnum.Success
      state.plans = action.payload.data as IPlan[]
    })
    builder.addCase(getPlansAsync.rejected, (state) => {
      state.plansStatus = StatusEnum.Failed
    })

    //Get Screening
    builder.addCase(getScreeningAvailabilityData.pending, (state) => {
      state.screeningStatus = StatusEnum.Pending
    })
    builder.addCase(getScreeningAvailabilityData.fulfilled, (state, action) => {
      state.screeningStatus = StatusEnum.Success
      state.screeningAvailabilityData = action.payload
    })
    builder.addCase(getScreeningAvailabilityData.rejected, (state) => {
      state.screeningStatus = StatusEnum.Failed
    })

    //verifyCodeAsync
    builder.addCase(verifyCodeAsync.pending, (state) => {
      state.verifyCodeStatus = StatusEnum.Pending
    })
    builder.addCase(verifyCodeAsync.fulfilled, (state, action) => {
      state.verifyCodeStatus = StatusEnum.Success
    })
    builder.addCase(verifyCodeAsync.rejected, (state) => {
      state.verifyCodeStatus = StatusEnum.Failed
    })

    //loginVerifyCodeAsync
    builder.addCase(loginVerifyCodeAsync.pending, (state) => {
      state.loginVerifyCodeStatus = StatusEnum.Pending
    })
    builder.addCase(loginVerifyCodeAsync.fulfilled, (state, action) => {
      state.loginVerifyCodeStatus = StatusEnum.Success
    })
    builder.addCase(loginVerifyCodeAsync.rejected, (state) => {
      state.loginVerifyCodeStatus = StatusEnum.Failed
    })

    //Wrong Number API
    builder.addCase(signUpEditApi.pending, (state) => {
      state.signUpEditApiAuthStatus = StatusEnum.Pending
    })
    builder.addCase(signUpEditApi.fulfilled, (state, action) => {
      state.signUpEditApiAuthStatus = StatusEnum.Success
      const actionPayload = action.payload.data.data as { token: string; user: UserData }
      state.token = actionPayload?.token
      state.user = actionPayload?.user
    })
    builder.addCase(signUpEditApi.rejected, (state) => {
      state.signUpEditApiAuthStatus = StatusEnum.Failed
    })
  },
})

export const { resetLogin, updateStripePaymentStatus, resetPaymentData, signUpData } = authSlice.actions

export default authSlice.reducer

export const selectAuthState = (state: RootState) => state.auth
