import axios from 'axios'
import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { APIResponse, IHeaderProps } from 'api/Interface'
import { AppConstants } from 'AppConstants/AppConstants'

export const BaseURL = process.env.REACT_APP_API_BASE_URL || '/api'

const defaultHeaders: IHeaderProps = {
  isAuth: true,
  AdditionalParams: {},
  isJsonRequest: true,
  api_key: true,
  isFormData: false,
}

export const ApiPostNoAuth = (type: string, userData?: unknown): Promise<APIResponse> => {
  return new Promise((resolve, reject) => {
    axios
      .post(BaseURL + type, userData, getHttpOptions({ ...defaultHeaders, isAuth: false }))
      .then((response) => {
        if (response.status >= 400) {
          reject({ status: response.status, data: response.data })
        } else {
          resolve({ status: response.status, data: response.data })
        }
      })
      .catch((error) => {
        if (error.response) {
          reject({ status: error.response.status, data: error.response.data })
        } else {
          reject({ status: null, data: null, error })
        }
      })
  })
}

export const ApiPutNoAuth = (type: string, userData: unknown) => {
  return new Promise((resolve, reject) => {
    axios
      .put(BaseURL + type, userData, getHttpOptions({ ...defaultHeaders, isAuth: false }))
      .then((response) => resolve(response.data))
      .catch((error) => {
        if (error?.response?.data?.error) {
          reject(error.response.data.error)
        } else {
          reject(error)
        }
      })
  })
}

export const ApiGetNoAuth = (type: string) => {
  return new Promise((resolve, reject) => {
    axios
      .get(BaseURL + type, getHttpOptions({ ...defaultHeaders, isAuth: false }))
      .then((response) => resolve(response.data))
      .catch((error) => {
        if (error?.response?.data?.error) {
          reject(error.response.data.error)
        } else {
          reject(error)
        }
      })
  })
}

export const ApiGet = (type: string): Promise<APIResponse> => {
  return new Promise((resolve, reject) => {
    axios
      .get(BaseURL + type, getHttpOptions())
      .then((response) => {
        resolve({ status: response.status, data: response.data })
      })
      .catch((error) => {
        if (error.response) {
          reject({ status: error.response.status, data: error.response.data })
        } else {
          reject({ status: null, data: null, error })
        }
      })
  })
}

export const ApiGetPaymentStatus = (type: string): Promise<APIResponse> => {
  return new Promise((resolve, reject) => {
    axios
      .get(BaseURL + type, getHttpOptions())
      .then((response) => {
        resolve({ status: response.status, data: response.data })
      })
      .catch((error) => {
        if (error.response) {
          reject({ status: error.response.status, data: error.response.data })
        } else {
          reject({ status: null, data: null, error })
        }
      })
  })
}

export const ApiPost = (
  type: string,
  userData?: unknown,
  AdditionalHeader?: Record<string, string>,
): Promise<APIResponse> => {
  return new Promise((resolve, reject) => {
    axios
      .post(BaseURL + type, userData, {
        ...getHttpOptions({ ...defaultHeaders, ...AdditionalHeader }),
      })
      .then((response) => {
        resolve({ status: response.status, data: response.data })
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          localStorage.clear()
        }

        if (error.response) {
          reject({ status: error.response.status, data: error.response.data })
        } else {
          reject({ status: null, data: null, error })
        }
      })
  })
}
export const ApiPostPlanId = (
  type: string,
  userData?: unknown,
  AdditionalHeader?: Record<string, string>,
): Promise<APIResponse> => {
  return new Promise((resolve, reject) => {
    axios
      .post(BaseURL + type, userData, {
        ...getHttpOptions({ ...defaultHeaders, ...AdditionalHeader }),
      })
      .then((response) => {
        resolve({ status: response.status, data: response.data })
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          localStorage.clear()
        }

        if (error.response) {
          reject({ status: error.response.status, data: error.response.data })
        } else {
          reject({ status: null, data: null, error })
        }
      })
  })
}
export const ApiVisitPost = (
  type: string,
  data?: unknown,
  AdditionalHeader?: Record<string, string>,
): Promise<APIResponse> => {
  return new Promise((resolve, reject) => {
    axios
      .post(BaseURL + type, data, {
        ...getHttpOptions({ ...defaultHeaders, ...AdditionalHeader }),
      })
      .then((response) => {
        resolve({ status: response.status, data: response.data })
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          localStorage.clear()
        }

        if (error.response) {
          reject({ status: error.response.status, data: error.response.data })
        } else {
          reject({ status: null, data: null, error })
        }
      })
  })
}
export const ApiPut = (type: string, userData?: unknown) => {
  return new Promise((resolve, reject) => {
    axios
      .put(BaseURL + type, userData, getHttpOptions())
      .then((response) => resolve(response.data))
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear()
        }
        if (error?.response?.data?.error) {
          reject(error.response.data.error)
        } else {
          reject(error)
        }
      })
  })
}

export const ApiPatch = (type: string, userData: unknown) => {
  return new Promise((resolve, reject) => {
    axios
      .patch(BaseURL + type, userData, getHttpOptions())
      .then((response) => resolve(response.data))
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear()
        }
        if (error?.response?.data?.error) {
          reject(error.response.data.error)
        } else {
          reject(error)
        }
      })
  })
}

export const ApiDelete = (type: string) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(BaseURL + type, getHttpOptions())
      .then((response) => resolve(response.data))
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear()
        }
        if (error?.response?.data?.error) {
          reject(error.response.data.error)
        } else {
          reject(error)
        }
      })
  })
}

export const ApiGetBuffer = (url: string) => {
  return new Promise((resolve, reject) => {
    fetch(url, { method: 'GET', mode: 'no-cors' })
      .then((response) => {
        if (response.ok) {
          return response.arrayBuffer()
        } else {
          resolve(null)
        }
      })
      .then((buffer) => resolve(buffer))
      .catch((error) => reject(error))
  })
}

export const getHttpOptions = (options = defaultHeaders) => {
  let headers: Record<string, string> = {}
  if (options?.isAuth) {
    if (localStorage.getItem(AppConstants.ACCESS_TOKEN)) {
      headers['Authorization'] = 'Bearer ' + localStorage.getItem(AppConstants.ACCESS_TOKEN)
    }
  }
  headers['x-platform-id'] = process.env.REACT_APP_PLATFORM_ID || ''
  if (options?.isJsonRequest && !options?.isFormData) {
    headers['Content-Type'] = 'application/json'
  }
  if (options?.isFormData) {
    headers['Content-Type'] = 'multipart/form-data'
  }
  if (options?.AdditionalParams) {
    headers = { ...headers, ...options.AdditionalParams }
  }
  return { headers }
}
