import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Avatar, Box, Button, CircularProgress, Grid, Modal, Typography } from '@mui/material'
import axios from 'axios'
import { isMobile } from 'react-device-detect'
import ReactGA from 'react-ga4'
import { useNavigate, useParams } from 'react-router-dom'
import Webcam from 'react-webcam'
import Footer from 'components/Footer'
import Header from 'components/Header'
import { useAppDispatch } from 'redux/store'
import style from './AddPhoto.module.scss'
const styless: any = {
  position: 'absolute' as 'absolute',
  top: '46%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 430,
  // bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}
const AddPhoto = () => {
  const navigate = useNavigate()
  const { id } = useParams()

  const [image, setImage] = useState<any>(null)
  const [uploadImg, setUploadImg] = useState(false)
  const [captureImg, setCaptureImg] = useState(false)
  const [error, setError] = useState(false)
  const dispatch = useAppDispatch()
  const [currentUserPic, setCurrentUserPic] = useState()
  //desktop pic upload states
  const [img, setImg]: any = useState(null)
  const webcamRef: any = useRef(null)
  const [webcamReady, setWebcamReady] = useState(false)

  const videoConstraints = {
    width: 420,
    height: 420,
    facingMode: 'user',
  }

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot()
    setImg(imageSrc)
  }, [webcamRef])

  const [open, setOpen] = React.useState(false)
  const handleOpen = async () => {
    const { state }: any = await navigator.permissions.query({ name: 'camera' as PermissionName })
    if (state === 'denied') {
      alert('camera permission denied, please allow to continue')
    }
    setOpen(true)
  }
  const handleClose = () => setOpen(false)
  const handleUserMedia = () => {
    setWebcamReady(true)
  }

  async function getProfile() {
    try {
      const response: any = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/auth/me`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          'x-platform-id': process.env.REACT_APP_PLATFORM_ID || '',
        },
      })
      if (response?.data?.data?.profilPic) {
        setCurrentUserPic(response?.data?.data?.profilPic)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleUploadPhoto = async (e: any) => {
    if (e.target.files) {
      const formData = new FormData()
      formData.append('file', e.target.files[0])

      if (e.target.files[0]) {
        try {
          setUploadImg(true)
          const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/me/profile-picture`, formData, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('userToken')}`,
              'x-platform-id': process.env.REACT_APP_PLATFORM_ID || '',
            },
          })

          if (response?.data?.profilePic) {
            setImage(response?.data?.profilePic)
            setUploadImg(false)
          } else {
            setUploadImg(false)
            console.error('Unexpectedformat:', response)
          }
        } catch (error) {
          console.error(error)
        }
      } else {
        setUploadImg(false)
      }
    }
    setUploadImg(false)
  }

  useEffect(() => {
    getProfile()
  }, [])

  useEffect(() => {
    ReactGA.event('page_view', {
      page_title: 'Referral|Add Photo',
      page_location: `/referral/${id}/add-photo`,
    })
  }, [])

  const handleCapturePhoto = async (e: any) => {
    setCaptureImg(true)
    if (e.target.files) {
      const formData = new FormData()
      formData.append('file', e.target.files[0])

      try {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/me/profile-picture`, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('userToken')}`,
            'x-platform-id': process.env.REACT_APP_PLATFORM_ID || '',
          },
        })

        if (response?.data?.profilePic) {
          setImage(response?.data?.profilePic)
          setCaptureImg(false)
        } else {
          setCaptureImg(false)
          console.error('Unexpectedformat:', response)
        }
      } catch (error) {
        console.error(error)
      }
    } else {
      setUploadImg(false)
    }
  }

  const onContinue = () => {
    if (
      (image === null || image === undefined || image === '') &&
      (currentUserPic === null || currentUserPic === undefined)
    ) {
      setError(true)
    } else {
      navigate(`/referral/${id}/physical-visit`)
    }
  }

  const handleCapturePhotoDesktop = async (e: any) => {
    const blob = await fetch(img).then((res) => res.blob())
    const formData = new FormData()
    formData.append('file', blob)
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/me/profile-picture`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          'x-platform-id': process.env.REACT_APP_PLATFORM_ID || '',
        },
      })

      if (response?.data?.profilePic) {
        setImage(response?.data?.profilePic)
        setCaptureImg(false)
        setOpen(false)
      } else {
        setCaptureImg(false)
        console.error('Unexpectedformat:', response)
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    const handleClickOutside = (e: any) => {
      if (
        webcamRef.current &&
        !webcamRef?.current?.videoElement?.contains(e.target) &&
        !e?.target?.classList?.contains('capture-button') // Check if click target is not the "Capture photo" button
      ) {
        setWebcamReady(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [webcamRef])

  return (
    <div className={style.container}>
      <div className={style.addPhotoDetails}>
        <Header headerText="Add a Profile Photo" showBackButton={false}></Header>

        <div className={style.content}>
          <p className={style.description}>Our doctors use your photo to recognize you when you visit.</p>
          <div className={style.uploadBar}>
            {image || currentUserPic ? (
              <Avatar className={style.avatar} alt="profile" src={image || currentUserPic}></Avatar>
            ) : (
              <label htmlFor="contained-button-file">
                <div className={style.circleDiv}>
                  <img className={style.circleImg} alt="circle" src={'/images/circle.svg'}></img>
                </div>
              </label>
            )}
          </div>
          <div className={style.buttonContainer}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleUploadPhoto}
                  style={{ display: 'none' }}
                  id="contained-button-file"
                />
                <label
                  htmlFor="contained-button-file"
                  onClick={() => {
                    setUploadImg(false)
                  }}
                >
                  <Button component="span" className={style.photoButton}>
                    {!uploadImg && <span>Upload Photo</span>}
                    {uploadImg && (
                      <CircularProgress
                        size={20}
                        sx={{
                          color: 'white',
                          width: '14px',
                          height: '14px',
                          left: '90%',
                        }}
                      />
                    )}
                  </Button>
                </label>
              </Grid>
              <Grid item xs={12}>
                {isMobile && (
                  <div>
                    <input
                      accept="image/*"
                      capture="user"
                      id="cameraInput"
                      type="file"
                      style={{ display: 'none' }}
                      onChange={handleCapturePhoto}
                    />
                    <label htmlFor="cameraInput">
                      <Button component="span" className={style.photoButton}>
                        {!captureImg && <span>Take Photo</span>}
                        {captureImg && (
                          <CircularProgress
                            size={20}
                            sx={{
                              color: 'white',
                              width: '14px',
                              height: '14px',
                              left: '90%',
                            }}
                          />
                        )}
                      </Button>
                    </label>
                  </div>
                )}
                {!isMobile && (
                  <div>
                    <input accept="image/*" id="cameraInput" style={{ display: 'none' }} onClick={handleOpen} />
                    <label htmlFor="cameraInput">
                      <Button component="span" className={style.photoButton}>
                        {!captureImg && <span>Take Photo</span>}
                        {captureImg && (
                          <CircularProgress
                            size={20}
                            sx={{
                              color: 'white',
                              width: '14px',
                              height: '14px',
                              left: '90%',
                            }}
                          />
                        )}
                      </Button>
                    </label>
                  </div>
                )}
              </Grid>
            </Grid>
          </div>
        </div>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styless}>
            <div
              className="Container"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                gap: '1rem',
              }}
            >
              {img === null ? (
                <>
                  <Webcam
                    audio={false}
                    mirrored={true}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    videoConstraints={videoConstraints}
                    style={{ width: '100%', height: '100%', borderRadius: '0.5rem' }}
                    onUserMedia={handleUserMedia}
                  />
                  {webcamReady && (
                    <Button
                      className="capture-button"
                      style={{ width: '10rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                      onClick={capture}
                    >
                      Capture Photo
                    </Button>
                  )}
                </>
              ) : (
                <>
                  <img
                    src={img}
                    alt="screenshot"
                    style={{ width: '100%', height: '100%', borderRadius: '0.5rem', marginBottom: '1rem' }}
                  />
                  <div style={{ display: 'flex', gap: '15rem' }}>
                    <img
                      src={'/images/retry.svg'}
                      style={{ width: 24, height: 24, cursor: 'pointer' }}
                      onClick={() => {
                        setWebcamReady(false)
                        setImg(null)
                      }}
                    ></img>
                    <img
                      src={'/images/emailSuccess.svg'}
                      style={{ width: 28, height: 28, cursor: 'pointer' }}
                      onClick={handleCapturePhotoDesktop}
                    ></img>
                  </div>
                </>
              )}
            </div>
          </Box>
        </Modal>
        <div className={style.btnWrapper}>
          <Grid item xs={12} className={style.errorGrid}>
            {error && (
              <div className={style.errorContainer}>
                <Typography sx={{ fontSize: '14px', fontWeight: 700 }}>Please upload your photo</Typography>
                <div
                  style={{
                    display: 'flex',
                    flex: '1',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    paddingRight: '12px',
                  }}
                >
                  <img
                    onClick={() => {
                      setError(false)
                    }}
                    style={{ cursor: 'pointer' }}
                    src={'/images/wrong.svg'}
                  ></img>
                </div>
              </div>
            )}
          </Grid>
          <Footer
            buttonText={'Continue'}
            isLoading={false}
            trackerIndex={2}
            isDisabled={image || currentUserPic ? false : true}
            buttonType={'submit'}
            onButtonClick={() => {
              image || currentUserPic ? onContinue() : undefined
            }}
          ></Footer>
        </div>
      </div>
    </div>
  )
}

export default AddPhoto
