import { useEffect, useState } from 'react'
import ReactGA from 'react-ga4'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Footer from 'components/Footer'
import Header from 'components/Header'
import styles from './ClinicWelcome.module.scss'

enum Device_Type {
  ios = 'IOS',
  android = 'Android',
  desktop = 'Desktop',
}

const ClinicWelcome = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const code = params.get('code')
  const [device, setDevice] = useState('')

  useEffect(() => {
    const userAgent = navigator.userAgent

    const isMobile = /iPhone|iPad|iPod|Android/i.test(userAgent)

    if (isMobile) {
      if (/iPad/i.test(userAgent)) {
        setDevice(Device_Type.ios)
      } else if (/iPhone/i.test(userAgent)) {
        setDevice(Device_Type.ios)
      } else if (/Android/i.test(userAgent)) {
        setDevice(Device_Type.android)
      }
    } else {
      setDevice(Device_Type.desktop)
    }
  }, [])

  const toAppStore = () => {
    window.location.href = `https://apps.apple.com/us/app/kiro-chiropractic-adjustments/id6475482372`
  }

  const toPlayStore = () => {
    window.location.href = `https://play.google.com/store/apps/details?id=com.getkiro.kiro`
  }

  useEffect(() => {
    ReactGA.event('page_view', {
      page_title: 'Referral|Clinic',
      page_location: `/clinic?code=${code}`,
    })
  }, [])

  return (
    <div>
      {code && (
        <div className={styles.container}>
          <Header headerText="" showBackButton={false}></Header>
          <div className={styles.welcomeContainer}>
            <div className={styles.headerText}>
              WELCOME <br></br> TO KIRO
            </div>
            <div className={styles.messageText}>
              To check in, please download the KIRO app below and scan the QR code from within the app.
            </div>
          </div>

          <Footer
            buttonText={'Download KIRO'}
            isLoading={false}
            trackerIndex={10}
            isDisabled={false}
            buttonType={'button'}
            onButtonClick={() => {
              if (device === Device_Type.android) {
                toPlayStore()
              } else if (device === Device_Type.ios) {
                toAppStore()
              } else {
                navigate(`/referral/${id}/download`)
              }
            }}
          ></Footer>
        </div>
      )}
    </div>
  )
}

export default ClinicWelcome
