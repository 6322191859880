import { Referral_Flow_Action_Type } from 'AppConstants/AppConstants'

export interface ITime {
  index: number
  value: string
}

//Selected Slot Persist
interface IScheduleInitialValues {
  scheduleObject: {
    appointmentTime: string
    timezone: string
  }
  index?: number | null
  value?: ITime | null
  date?: any
}
interface SelectedSlotReferralFlowState {
  selectedSlotReferralFlow: IScheduleInitialValues | null
}
const selectedSlotReferralFlowState: SelectedSlotReferralFlowState = {
  selectedSlotReferralFlow: null,
}

export const SetSelectedSlotReferralFlow = (selectedSlotReferralFlow: IScheduleInitialValues | null) => ({
  type: Referral_Flow_Action_Type.SELECTED_SLOT_REFERRAL_FLOW,
  payload: selectedSlotReferralFlow,
})

export const selectedSlotReferralFlowPersist = (
  state = selectedSlotReferralFlowState,
  action: { type: Referral_Flow_Action_Type; payload: any },
) => {
  switch (action.type) {
    case Referral_Flow_Action_Type.SELECTED_SLOT_REFERRAL_FLOW:
      return {
        ...state,
        selectedSlotReferralFlow: action.payload as IScheduleInitialValues,
      }
    default:
      return state
  }
}

// // Slot booked already Persist

// interface SlotBookedError {
//   slotBookedAlreadyReferralFlow: boolean
// }
// const slotBookedAlreadyReferralFlowState: SlotBookedError = {
//   slotBookedAlreadyReferralFlow: false,
// }

// export const SetSlotBookedAlreadyReferralFlow = (slotBookedAlreadyReferralFlow: boolean) => ({
//   type: Referral_Flow_Action_Type.SLOT_BOOKED_ALREADY_REFERRAL_FLOW,
//   payload: slotBookedAlreadyReferralFlow,
// })

// export const slotBookedReferralFlowPersist = (
//   state = slotBookedAlreadyReferralFlowState,
//   action: { type: Referral_Flow_Action_Type; payload: any },
// ) => {
//   switch (action.type) {
//     case Referral_Flow_Action_Type.SLOT_BOOKED_ALREADY_REFERRAL_FLOW:
//       return {
//         ...state,
//         slotBookedAlreadyReferralFlow: action.payload,
//       }
//     default:
//       return state
//   }
// }
