import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  hippa: false,
  liability: false,
  info: false,
}

const waiverSlice = createSlice({
  name: 'waiver',
  initialState,
  reducers: {
    setHippaAccept: (state, action) => {
      state.hippa = action.payload
    },
    setLiabilityAccept: (state, action) => {
      state.liability = action.payload
    },
    setInfoAccept: (state, action) => {
      state.info = action.payload
    },
  },
})

export const { setHippaAccept, setInfoAccept, setLiabilityAccept } = waiverSlice.actions

export default waiverSlice.reducer
