import React, { useMemo, FC, MouseEvent, useState, useEffect } from 'react'
import { differenceInCalendarDays, getDay, isMonday, previousMonday } from 'date-fns'
import Slider from 'react-slick'
import styles from './HorizontalCalendar.module.scss'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

interface Props {
  selectedDate: Date
  setSelectedDate: React.Dispatch<React.SetStateAction<Date>>
  onClick?: (event: MouseEvent<HTMLDivElement>) => void
}

function getDayString(date: Date): string {
  return date.toLocaleString('en-US', { weekday: 'short' })
}

function isSameDay(date1: Date, date2: Date): boolean {
  return date1.getDate() === date2.getDate()
}

// const generateHorizontalCalendarDates = (): Date[] => {
//   const today = new Date()
//   const days = new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate()
//   const result: Date[] = []
//   for (let i = 0; i < days; i++) {
//     result[i] = new Date(today.getFullYear(), today.getMonth(), days - i)
//   }
//   return result.reverse() // Reverse the array to get dates from 01 to 31
// }

// const generateHorizontalCalendarDates = (): Date[] => {
//   const today = new Date()
//   const daysInMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate()
//   const daysToShow = Math.min(daysInMonth, 30) // Show 30 days or less if the month has fewer days
//   const result: Date[] = []

//   for (let i = 0; i < daysToShow; i++) {
//     result[i] = new Date(today.getFullYear(), today.getMonth(), today.getDate() + i)
//   }

//   return result
// }
const generateHorizontalCalendarDates = (): Date[] => {
  const today = new Date()
  let startOfWeek = today
  if (!isMonday(today)) {
    startOfWeek = previousMonday(today)
  }

  const daysInMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate()
  const daysToShow = Math.min(daysInMonth, 14) // Show 30 days or less if the month has fewer days
  const result: Date[] = []

  for (let i = 0; i < daysToShow; i++) {
    result[i] = new Date(startOfWeek.getFullYear(), startOfWeek.getMonth(), startOfWeek.getDate() + i)
  }

  return result
}

const HorizontalCalendar: FC<Props> = ({ selectedDate, setSelectedDate, onClick }) => {
  const today = new Date()
  const firstDateOfWeek = getDay(today) - 1
  const dates: Date[] = useMemo(() => {
    return generateHorizontalCalendarDates()
  }, [])

  const onDatePress = (date: Date): void => {
    setSelectedDate(date)
  }
  const combinedClick = (event: MouseEvent<HTMLDivElement>, item: Date) => {
    if (new Date(item) >= new Date(today.getFullYear(), today.getMonth(), today.getDate())) {
      onDatePress(item)
      if (onClick) {
        onClick(event)
      }
    }
  }
  // const selectedDateIndex = dates.findIndex((date) => isSameDay(selectedDate, date))
  const selectedDateIndex = dates.findIndex((date) => {
    const startOfWeek = new Date(date.getFullYear(), date.getMonth(), date.getDate() - date.getDay() + 1)
    const endOfWeek = new Date(date.getFullYear(), date.getMonth(), date.getDate() + (6 - date.getDay()))
    return isSameDay(selectedDate, startOfWeek) || isSameDay(selectedDate, endOfWeek)
  })

  const [showRightArrow, setShowRightArrow] = useState(true)
  const [showLeftArrow, setShowLeftArrow] = useState(false)

  const slickSettings = useMemo(() => {
    let diff = differenceInCalendarDays(selectedDate, dates[0])

    const initialSlide = diff > 6 ? 7 : 0
    return {
      infinite: false,
      speed: 500,
      dots: false,
      arrows: true,
      slidesToShow: 7,
      initialSlide: initialSlide,
      slidesToScroll: 7,
      nextArrow: <NextArrow showArrow={showRightArrow} className={styles.nextArrow} />,
      prevArrow: <PrevArrow showArrow={showLeftArrow} className={styles.prevArrow} />,
      beforeChange: (oldIndex: number, newIndex: number) => {
        if (newIndex === 7) {
          setShowRightArrow(false)
          setShowLeftArrow(true)
        }
        if (newIndex === 0) {
          setShowLeftArrow(false)
          setShowRightArrow(true)
        }
      },
      responsive: [
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 7,
          },
        },
      ],
    }
  }, [selectedDate, showRightArrow, showLeftArrow])

  useEffect(() => {
    let difference = differenceInCalendarDays(selectedDate, dates[0])
    if (difference && difference <= 6) {
      setShowRightArrow(true)
      setShowLeftArrow(false)
    } else if (difference > 6) {
      setShowRightArrow(false)
      setShowLeftArrow(true)
    }
  }, [])

  return (
    <Slider {...slickSettings}>
      {dates.map((item: Date) => {
        const dayNumber: string = item.getDate().toString()
        const dayString: string = getDayString(item)
        const isActive: boolean = isSameDay(selectedDate, item)

        return (
          <div
            key={item.toDateString()}
            onClick={(e: MouseEvent<HTMLDivElement>) => combinedClick(e, item)}
            className={`
              ${styles.dayContainer}
              ${isActive ? styles?.selectedDay : ''}
              ${
                new Date(item) < new Date(today.getFullYear(), today.getMonth(), today.getDate())
                  ? styles?.disabledDay
                  : ''
              }
              `}
          >
            <div className={styles.date}>{dayNumber}</div>
            <div className={styles.day}>{dayString.toUpperCase()}</div>
          </div>
        )
      })}
    </Slider>
  )
}

export default HorizontalCalendar

export function NextArrow(props: any) {
  const { className, style, onClick, showArrow } = props
  const [isMobile, setIsMobile] = useState(false)

  //detect device
  useEffect(() => {
    const userAgent = navigator.userAgent

    const isMobile = /iPhone|iPad|iPod|Android/i.test(userAgent)

    if (isMobile) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }

    return () => {}
  }, [])
  console.log(isMobile, 'is  mobile')

  return (
    <div>
      <img
        className={className}
        src={'/images/nextArrow.svg'}
        alt="next arrow"
        style={{
          ...style,
          height: '50px',
          width: '20px',
          position: 'absolute',
          marginRight: '0.6rem',
          paddingBottom: '0.3rem',
          display: showArrow ? 'block' : 'none',
        }}
        onClick={!isMobile ? onClick : undefined}
      ></img>
    </div>
  )
}
export function PrevArrow(props: any) {
  const { className, style, onClick, showArrow } = props
  const [isMobile, setIsMobile] = useState(false)

  //detect device
  useEffect(() => {
    const userAgent = navigator.userAgent

    const isMobile = /iPhone|iPad|iPod|Android/i.test(userAgent)

    if (isMobile) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }

    return () => {}
  }, [])

  return (
    <div>
      <img
        className={className}
        src={'/images/prevArrow.svg'}
        alt="next arrow"
        style={{
          ...style,
          height: '50px',
          width: '20px',
          position: 'absolute',
          marginLeft: '0.6rem',
          paddingBottom: '0.3rem',
          display: showArrow ? 'block' : 'none',
        }}
        onClick={!isMobile ? onClick : undefined}
      ></img>
    </div>
  )
}
