import { Action_Type } from 'AppConstants/AppConstants'
import { ITime } from 'pages/V1/PhysicalVisit'

//Redux Persilst

// Available Plan Persist

export interface PlanDetails {
  amount: number
  count: number
  frequency: null
  id: string
  isActive: boolean
  description: string
  maxCount: number
  name: string
  stripeProductId: string
  type: string
}

interface AvailablePlanPersistState {
  availablePlans: Array<PlanDetails>
}
const availablePlanPersistState: AvailablePlanPersistState = {
  availablePlans: [],
}

export const SetAvailablePlans = (availablePlans: any) => ({
  type: Action_Type.AVAILABLE_PLAN,
  payload: availablePlans,
})

export const availablePlanPersist = (
  state = availablePlanPersistState,
  action: { type: Action_Type; payload: any },
) => {
  switch (action.type) {
    case Action_Type.AVAILABLE_PLAN:
      return {
        ...state,
        availablePlans: action.payload as Array<PlanDetails>,
      }
    default:
      return state
  }
}

//Selected Plan Persist
interface SelectedPlan {
  clientSecret: string
  paymentId: string
  paymentIntentId: string
}

interface SelectedPlanPersistState {
  selectedPlan: SelectedPlan | null
}
const selectedPlanPersistState: SelectedPlanPersistState = {
  selectedPlan: null,
}

export const SetSelectedPlan = (selectedPlan: SelectedPlan | null) => ({
  type: Action_Type.SELECTED_PLAN,
  payload: selectedPlan,
})

export const selectedPlanPersist = (state = selectedPlanPersistState, action: { type: Action_Type; payload: any }) => {
  switch (action.type) {
    case Action_Type.SELECTED_PLAN:
      return {
        ...state,
        selectedPlan: action.payload as SelectedPlan,
      }
    default:
      return state
  }
}

//Selected Slot Persist
interface IScheduleInitialValues {
  scheduleObject: {
    appointmentTime: string
    timezone: string
  }
  index?: number | null
  value?: ITime | null
  date?: any
}
interface SelectedSlotPersistState {
  selectedSlot: IScheduleInitialValues | null
}
const selectedSlotPersistState: SelectedSlotPersistState = {
  selectedSlot: null,
}

export const SetSelectedSlot = (selectedSlot: IScheduleInitialValues | null) => ({
  type: Action_Type.SELECTED_SLOT,
  payload: selectedSlot,
})

export const selectedSlotPersist = (state = selectedSlotPersistState, action: { type: Action_Type; payload: any }) => {
  switch (action.type) {
    case Action_Type.SELECTED_SLOT:
      return {
        ...state,
        selectedSlot: action.payload as IScheduleInitialValues,
      }
    default:
      return state
  }
}

//Selected Plan Info Persist

interface SelectedPlanInfoPersistState {
  selectedPlanInfo: PlanDetails | null
}
const selectedPlanInfoPersistState: SelectedPlanInfoPersistState = {
  selectedPlanInfo: null,
}

export const SetSelectedPlanInfo = (selectedPlanInfo: PlanDetails | null) => ({
  type: Action_Type.SELECTED_PLAN_INFO,
  payload: selectedPlanInfo,
})

export const selectedPlanInfoPersist = (
  state = selectedPlanInfoPersistState,
  action: { type: Action_Type; payload: any },
) => {
  switch (action.type) {
    case Action_Type.SELECTED_PLAN_INFO:
      return {
        ...state,
        selectedPlanInfo: action.payload as PlanDetails,
      }
    default:
      return state
  }
}

// Slot booked already Persist

interface SlotBookedErrorType {
  slotBookedAlready: boolean
}
const slotBookedAlreadyState: SlotBookedErrorType = {
  slotBookedAlready: false,
}

export const SetSlotBookedAlready = (slotBooked: boolean) => ({
  type: Action_Type.SLOT_BOOKED_ALREADY,
  payload: slotBooked,
})

export const slotBookedPersist = (state = slotBookedAlreadyState, action: { type: Action_Type; payload: any }) => {
  switch (action.type) {
    case Action_Type.SLOT_BOOKED_ALREADY:
      return {
        ...state,
        slotBookedAlready: action.payload,
      }
    default:
      return state
  }
}

// Payment Done Persist

interface PaymentDone {
  paymentDone: boolean
}
const paymentDoneState: PaymentDone = {
  paymentDone: false,
}

export const SetPaymentDone = (paymentDone: boolean) => ({
  type: Action_Type.PAYMENT_DONE,
  payload: paymentDone,
})

export const paymentDonePersist = (state = paymentDoneState, action: { type: Action_Type; payload: any }) => {
  switch (action.type) {
    case Action_Type.PAYMENT_DONE:
      return {
        ...state,
        paymentDone: action.payload,
      }
    default:
      return state
  }
}
