export function decodeToken(localToken: any) {
  let tokenParts = localToken.split('.')

  let tokenPayload = tokenParts[1]
  let tokenPayloadStr = atob(tokenPayload)

  let decodedToken: any = JSON.parse(tokenPayloadStr)

  let tempCurrentRole: any = ''
  if (decodedToken) {
    if (decodedToken.roles && decodedToken.roles.length === 1) {
      tempCurrentRole = decodedToken.roles[0]
    }
  } else {
    tempCurrentRole = null
  }
  return tempCurrentRole
}
