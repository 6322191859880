export function calculateDob(dob: string) {
  if (dob) {
    const dateArray = dob.split('-')
    const dobYear = parseInt(dateArray[0], 10)
    const dobMonth = parseInt(dateArray[1], 10)
    const dobDay = parseInt(dateArray[2], 10)
    const currentDate = new Date()

    const hasPassedBirthdayThisYear =
      currentDate.getMonth() > dobMonth - 1 ||
      (currentDate.getMonth() === dobMonth - 1 && currentDate.getDate() >= dobDay)
    const age = currentDate.getFullYear() - dobYear - (hasPassedBirthdayThisYear ? 0 : 1)
    return age
  } else {
    return 0
  }
}
