import { useEffect, useState } from 'react'
import { StatusEnum } from 'redux/types'
import { AppConstants } from '../AppConstants/AppConstants'
//import * as jwt from 'jsonwebtoken'
function useAuth() {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [isAuthLoading, setIsAuthLoading] = useState(true)
  const [token, setToken] = useState<string>('')
  const [user, setUser] = useState<any>(null)
  let [currentRole, _setCurrentRole] = useState<any>([])
  function setCurrentRole(role: string) {
    localStorage.setItem(AppConstants.CURRENTROLE_TOKEN, role)
    _setCurrentRole(role)
  }

  console.log(currentRole, 'this is the current role')

  useEffect(() => {
    let localToken = localStorage.getItem(AppConstants.ACCESS_TOKEN)
    console.log(localToken, 'local token ')

    if (localToken) {
      try {
        let tokenParts = localToken.split('.')

        let tokenPayload = tokenParts[1]
        let tokenPayloadStr = atob(tokenPayload)

        let decodedToken: any = JSON.parse(tokenPayloadStr)

        // let decodedToken: any = jwt.decode(localToken)
        if (decodedToken?.userId) {
          setUser(decodedToken)
          setIsAuthenticated(true)
          setToken(localToken)
        }

        let localCurrentRole = localStorage.getItem(AppConstants.CURRENTROLE_TOKEN)
        console.log(localCurrentRole, 'localrolebeforeCheck')
        let tempCurrentRole: any = ''
        if (!localCurrentRole) {
          if (decodedToken.roles && decodedToken.roles.length === 1) {
            tempCurrentRole = decodedToken.roles[0]
          }

          localStorage.setItem(AppConstants.CURRENTROLE_TOKEN, tempCurrentRole)
        } else {
          if (decodedToken.roles.includes(localCurrentRole)) {
            tempCurrentRole = localCurrentRole
          } else {
            tempCurrentRole = null
          }
        }

        setCurrentRole(tempCurrentRole)
      } catch (err) {
        console.log(err)
      }
    }

    setIsAuthLoading(false)
  }, [setIsAuthenticated, setToken])

  async function signOut() {
    localStorage.removeItem(AppConstants.ACCESS_TOKEN)
    localStorage.removeItem(AppConstants.CURRENTROLE_TOKEN)
    window.location.href = '/'
  }

  return {
    isAuthenticated,
    token,
    user,
    isAuthLoading,
    signOut,
    currentRole,
    setCurrentRole,
  }
}

export default useAuth
