import React, { useEffect, useState } from 'react'
import { Grid, IconButton, Typography } from '@mui/material'
import { DateField, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import axios from 'axios'
import { format } from 'date-fns'
import ReactGA from 'react-ga4'
import { useNavigate, useParams } from 'react-router-dom'
import Footer from 'components/Footer'
import Header from 'components/Header'
import { useAppDispatch } from 'redux/store'
import { StatusEnum } from 'redux/types'
import { EMAIL_REGEX } from 'regexConstants'
import { calculateDob } from 'utils/calculateDob'
import { decodeToken } from 'utils/decodeToken'
import styles from './Dob.module.scss'
import 'react-phone-input-2/lib/material.css'
import { AppConstants } from '../../../AppConstants/AppConstants'

interface InitialValue {
  dob: string
}

const Dob: React.FC = () => {
  const initialValues: InitialValue = {
    dob: '',
  }

  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [data, setData] = useState(initialValues)
  const [value, setValue] = useState<any>(null)
  const [dobValue, setDobValue] = useState<Date | any>(null)
  const [dobError, setDobError] = useState(false)
  const [errorType, setErrorType] = useState('')
  const [userDetails, setUserDetails] = useState<any>()
  console.log(dobValue, 'dv')

  const submitDob = async () => {
    let dobErr = false
    if (dobError) {
      setDobError(true)
      setErrorType('Incorrect-Dob')
      dobErr = true
    } else if (dobValue === '' || dobValue === null) {
      setValue(data.dob)
      setErrorType('Incorrect-Dob')
      setDobError(true)
      dobErr = true
    } else {
      const formattedDob = format(dobValue, 'yyyy-MM-dd')
      const dateOfBirth = new Date(formattedDob)
      if (calculateDob(formattedDob) >= 18) {
        setValue(dobValue)
        setDobError(false)
      } else if (dateOfBirth.getFullYear() <= 1900) {
        setDobError(true)
        setErrorType('Incorrect-Dob')
        dobErr = true
      } else {
        setErrorType('under_18')
        dobErr = true
      }
    }

    if (!dobErr) {
      const formattedDob = format(dobValue, 'yyyy-MM-dd')
      setData({ ...data, dob: formattedDob })

      const response: any = await axios
        .put(
          `${AppConstants.API_URL}/auth/me/${userDetails.id}`,
          {
            ...data,
            firstname: userDetails.firstname,
            lastname: userDetails.lastname,
            dob: formattedDob,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('userToken')}`,
              'x-platform-id': process.env.REACT_APP_PLATFORM_ID || '',
            },
          },
        )
        .then((res) => res)
        .catch((err) => err.response)

      if (response && response?.data?.code === 'success') {
        navigate(`/offers/${id}/physical-visit`)
      } else if (response && response.data && response.data.code >= 400 && response.data.code <= 599) {
        setErrorType('Error_Occurred')
      } else {
        return true
      }
    }
  }

  async function getProfile() {
    try {
      const response: any = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/auth/me`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          'x-platform-id': process.env.REACT_APP_PLATFORM_ID || '',
        },
      })
      if (response && response?.data.code === 'success') {
        setUserDetails({
          firstname: response?.data?.data?.firstname,
          lastname: response?.data?.data?.lastname,
          id: response?.data?.data?.id,
        })
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    ReactGA.event('page_view', {
      page_title: 'Offers|Dob',
      page_location: `/offers/${id}/dob`,
    })
  }, [])

  useEffect(() => {
    getProfile()
  }, [])

  return (
    <div className={styles.container}>
      {/* <Header headerText={'Enter Date of Birth'} showBackButton={false}></Header> */}
      <div className={styles.verifyOtpContainer}>
        {/* <p className={styles.codeText}>Enter your date of birth</p> */}
        <form
          onSubmit={(e) => {
            e.preventDefault()
          }}
        >
          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateField
                name="datefield"
                value={value}
                sx={
                  dobError
                    ? {
                        backgroundImage: "url('images/error.svg')",
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'right 15px center',
                      }
                    : {}
                }
                onError={(error: any) => {
                  console.log(error, 'aaaaaaaa')
                  if (error) {
                    setDobError(true)
                  } else {
                    setDobError(false)
                  }
                }}
                onChange={(value: any) => {
                  setDobValue(value)
                  setDobError(false)
                }}
                label="Date of Birth"
                disableFuture
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    dobValue === null ? undefined : submitDob()
                  }
                }}
              ></DateField>
            </LocalizationProvider>
          </Grid>
        </form>

        <Grid item xs={12}>
          {errorType && (
            <div className={styles.errorContainer}>
              <Typography sx={{ fontSize: '14px', fontWeight: 700 }}>
                {errorType === 'unknown' && `Unknown error`}
                {errorType === 'Incorrect-Dob' && `Invalid Date of Birth`}
                {errorType === 'Error_Occurred' && `Oops! something went wrong`}
                {errorType === 'under_18' && `You must be 18 or older to sign up`}
              </Typography>
              <div
                style={{
                  display: 'flex',
                  flex: '1',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  paddingRight: '12px',
                }}
              >
                <img
                  onClick={() => {
                    setErrorType('')
                  }}
                  style={{ cursor: 'pointer' }}
                  src={'/images/wrong.svg'}
                ></img>
              </div>
            </div>
          )}
        </Grid>
      </div>
      <Footer
        buttonText={'Continue'}
        isLoading={false}
        trackerIndex={3}
        shouldShowFive={true}
        shouldShowSix={true}
        isDisabled={dobValue === null}
        buttonId={'dob-submit'}
        buttonType={'submit'}
        onButtonClick={() => {
          dobValue === null ? undefined : submitDob()
        }}
      ></Footer>
    </div>
  )
}

export default Dob
