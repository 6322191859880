import React, { useEffect, useMemo, useState } from 'react'
import { CircularProgress, Grid, Typography, Button } from '@mui/material'
import { Elements, useElements, useStripe } from '@stripe/react-stripe-js'
import { StripeElementsOptions, StripeExpressCheckoutElementOptions, loadStripe } from '@stripe/stripe-js'
import axios from 'axios'
import ReactGA from 'react-ga4'
import { useNavigate } from 'react-router-dom'
import { AppConstants } from 'AppConstants/AppConstants'
import Footer from 'components/Footer'
import Header from 'components/Header'
// import { analytics } from 'index'
import { IPlan } from 'redux/auth/Interface'
import { PlanDetails, SetAvailablePlans, SetSelectedPlan, SetSelectedPlanInfo } from 'redux/persist'
import { useAppDispatch, useAppSelector } from 'redux/store'
import { StatusEnum } from 'redux/types'
import styles from './ChoosePlan.module.scss'
import { CheckoutForm } from '../Payment/CheckoutForm'

const ChoosePlan = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [clientSecret, setClientSecret]: any = useState(null)
  const [selectedPlanId, setSelectedPlanId] = useState('')
  const [error, setError] = useState(false)
  const [disableButton, setDisableButton] = useState(true)
  const [loadingButton, setLoadingButton] = useState(false)
  const plans = useAppSelector((state) => state.availablePlan.availablePlans)
  const [fetchPlanStatus, setFetchPlanStatus] = useState<StatusEnum>(StatusEnum.Idle)
  const [userInfo, setUserInfo]: any = useState(null)
  const selectedPlanInfo = useAppSelector((state) => state.selectedPlanInfo.selectedPlanInfo)

  //cardPayment
  const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLISH_KEY || ''
  const stripePromise = loadStripe(STRIPE_PUBLIC_KEY)

  const options: StripeElementsOptions = {
    clientSecret: clientSecret,
    appearance: {
      theme: 'night',
      variables: {
        colorBackground: '#050624',
      },
    },
  }

  let selectedPlan = useMemo(() => {
    if (plans && selectedPlanId) {
      return plans.filter((p: any) => p.id === selectedPlanId)[0]
    } else {
      return null
    }
  }, [plans, selectedPlanId])

  //choose plan

  const onContinue = async () => {
    console.log('on continue')
    setLoadingButton(true)
    purchasePlan(selectedPlanInfo?.id)

    // if (selectedPlan) {
    //   ReactGA.event('begin_checkout', {
    //     currency: 'USD',
    //     value: selectedPlan.amount / 100,
    //     items: [
    //       {
    //         item_id: selectedPlan.id,
    //         item_name: selectedPlan.name,
    //       },
    //     ],
    //   })
    // }
  }

  async function getProfile() {
    try {
      const response: any = await axios.get(`${AppConstants.API_URL}/auth/me`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          'x-platform-id': process.env.REACT_APP_PLATFORM_ID || '',
        },
      })
      setUserInfo(response?.data?.data)
      if (response && response?.data?.data?.initialPayment === true) {
        // navigate('/complete-registration')
      } else if (response?.data?.statusCode === 401) {
        localStorage.clear()
        sessionStorage.clear()
        navigate('/login')
      }
    } catch (error) {
      console.error(error)
    }
  }

  async function getPlans() {
    setFetchPlanStatus(StatusEnum.Pending)
    try {
      const response: any = await axios.get(`${AppConstants.API_URL}/payment-plan`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          'x-platform-id': process.env.REACT_APP_PLATFORM_ID || '',
        },
      })
      if (response && response?.data && response?.data?.data) {
        dispatch(SetAvailablePlans(response?.data?.data))

        setFetchPlanStatus(StatusEnum.Success)
      } else if (response?.data?.statusCode === 401) {
        setFetchPlanStatus(StatusEnum.Failed)
        // navigate('/login')
        console.log('error')
        //call reset login
      }
    } catch (error) {
      setFetchPlanStatus(StatusEnum.Failed)
      console.error(error)
    }
  }

  async function purchasePlan(selectedPlanId: string | undefined) {
    try {
      const response: any = await axios
        .post(
          `${AppConstants.API_URL}/payment-plan/purchase`,
          { planId: selectedPlanId },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('userToken')}`,
              'x-platform-id': process.env.REACT_APP_PLATFORM_ID || '',
            },
          },
        )
        .then((res) => {
          if (res && res?.data && res?.data?.data) {
            // setClientSecret(res?.data?.data?.clientSecret)
            // setPlanIdSecret(res?.data?.data?.paymentId)
            dispatch(SetSelectedPlan(res?.data?.data))
            navigate('/physical-visit')
          }
        })
        .catch((err) => {
          if (err) {
            setLoadingButton(false)
            alert('Something went wrong. Please try again.')
          }
        })
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    getPlans()
    getProfile()
    if (selectedPlanInfo) {
      setDisableButton(false)
    }
  }, [])

  // useEffect(() => {
  //   analytics.page()
  // }, [])

  useEffect(() => {
    ReactGA.event('page_view', {
      page_title: 'Choose Plan',
      page_location: '/choose-plan',
    })
  }, [])

  return (
    <>
      <div className={styles.container}>
        <Header
          headerText="Choose a Plan"
          showBackButton={false}
          // onBack={() => {
          //   navigate('/add-photo')
          // }}
        ></Header>
        <div className={styles.planDetails}>
          {fetchPlanStatus === StatusEnum.Pending && (
            <div className={styles.loadingWrapper}>
              <CircularProgress style={{ color: '#007f7c' }} size={30}></CircularProgress>
            </div>
          )}
          {fetchPlanStatus === StatusEnum.Success && (
            <div>
              <form>
                <Grid container spacing={2}>
                  {plans?.map((plan: PlanDetails, index: number) => {
                    return (
                      <Grid item xs={12} key={index}>
                        <div
                          onClick={(e) => {
                            // setSelectedPlanId(plan?.id)
                            dispatch(SetSelectedPlanInfo(plan))
                            setDisableButton(false)
                          }}
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            padding: '10px 15px',
                            border: selectedPlanInfo?.id === plan.id ? '2px solid #007F7C' : '2px solid #32344b',
                            borderRadius: '6px',
                          }}
                        >
                          <div
                            style={{
                              color: 'white',
                              display: 'flex',
                              justifyContent: 'space-between',
                              fontWeight: 'bold',
                            }}
                          >
                            <span style={{ textTransform: 'capitalize' }}>{plan?.name}</span>
                            <span style={{ fontWeight: '300' }}>
                              $ {plan.name === 'Membership' ? `${plan.amount / 100}/mo.` : plan.amount / 100}
                            </span>
                          </div>
                          <div
                            style={{
                              color: 'white',
                              display: 'flex',
                              justifyContent: 'space-between',
                              marginTop: '15px',
                            }}
                          >
                            <p>{plan?.description}</p>
                          </div>
                        </div>
                      </Grid>
                    )
                  })}
                </Grid>
              </form>
              {/* <div
                style={{
                  color: '#E5E7EB',
                  fontSize: '16px',
                  marginTop: '30px',
                  marginLeft: '1px',
                }}
              >
                <b>All Plans Include a Complimentary Screening</b>
                <br />
                <div style={{ marginTop: '1rem', lineHeight: '1.5rem' }}>
                  Following your screening, if you choose to opt-out, we'll refund you 100%.
                </div>
              </div> */}
            </div>
          )}
        </div>

        <div className={styles.btnWrapper}>
          <Grid item xs={12}>
            {error && (
              <div className={styles.errorContainer}>
                <Typography sx={{ fontSize: '14px', fontWeight: 700 }}>Please choose a plan you want ?</Typography>
                <div
                  style={{
                    display: 'flex',
                    flex: '1',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    paddingRight: '12px',
                  }}
                >
                  <img
                    onClick={() => {
                      setError(false)
                    }}
                    style={{ cursor: 'pointer' }}
                    src={'images/wrong.svg'}
                  ></img>
                </div>
              </div>
            )}
          </Grid>
        </div>
        <Footer
          buttonText={'Continue'}
          isLoading={loadingButton}
          trackerIndex={2}
          shouldShowFive={true}
          isDisabled={disableButton}
          buttonType={'submit'}
          onButtonClick={() => {
            disableButton ? undefined : onContinue()
          }}
        ></Footer>
      </div>

      {/* {!showPlan && (
        <div className={styles.paymentContainer}>
          <Header
            headerText="Add Payment Details"
            showBackButton={true}
            onBack={() => {
              disableBackButton ? undefined : setShowPlan(true)
            }}
          ></Header>
          <Elements stripe={stripePromise} options={options}>
            <div className={styles.paymentContainerDetails}>
              <CheckoutForm
                clientSecret={clientSecret}
                planId={planIdSecret}
                purchaseAmount={selectedPlan ? selectedPlan.amount / 100 : 60}
                plan={selectedPlan}
                disableBackButton={() => {
                  setDisableBackButton(true)
                }}
                enableBackButton={() => {
                  setDisableBackButton(false)
                }}
                showPlan={() => {
                  setShowPlan(true)
                }}
                userInfo={userInfo}
              ></CheckoutForm>
            </div>
          </Elements>
        </div>
      )} */}
    </>
  )
}

export default ChoosePlan
