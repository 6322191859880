import { useEffect, useState } from 'react'
import ReactGA from 'react-ga4'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Footer from 'components/Footer'
import Header from 'components/Header'
import styles from './Home.module.scss'

const Home = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    ReactGA.event('page_view', {
      page_title: 'Referral|Home',
      page_location: `/referral/${id}`,
    })
  }, [])

  return (
    <div>
      <div className={styles.container}>
        <Header headerText="" showBackButton={false}></Header>
        <div className={styles.welcomeContainer}>
          <div className={styles.headerText}>
            JOIN THE <br></br>MOVEMENT
          </div>
          <div className={styles.messageText}>
            Click the button below and complete the registration to claim your free adjustment.
          </div>
        </div>

        <Footer
          buttonText={'Claim Free Adjustment'}
          isLoading={false}
          trackerIndex={10}
          isDisabled={false}
          buttonType={'button'}
          onButtonClick={() => {
            navigate(`/referral/${id}/login`)
          }}
        ></Footer>
      </div>
    </div>
  )
}

export default Home
