import React, { useEffect, useState } from 'react'
import { FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Typography } from '@mui/material'
import { ClearIcon, DateField, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import axios from 'axios'
import { format, parseISO } from 'date-fns'
import ReactGA from 'react-ga4'
import { useNavigate } from 'react-router-dom'
import Footer from 'components/Footer'
import Header from 'components/Header'
import Button from 'components/shared/button'
import TextField from 'components/shared/textField'
// import { analytics } from 'index'
import { resendOTPAsync, signUpEditApi, verifyCodeAsync } from 'redux/auth'
// import { RootState, useAppDispatch, useAppSelector } from 'redux/store'
import { SetAvailablePlans, SetSelectedPlan, SetSelectedPlanInfo, SetSelectedSlot } from 'redux/persist'
import { useAppDispatch } from 'redux/store'
import { EMAIL_REGEX } from 'regexConstants'
import { calculateDob } from 'utils/calculateDob'
import styles from './Signup.module.scss'
import 'react-phone-input-2/lib/material.css'
import { AppConstants } from '../../../AppConstants/AppConstants'
import useAuth from '../../../hooks/useAuth'

interface InitialValues {
  firstname: string
  lastname: string
  dob: any
  // phone: string
  email: string
  // gender: string
  signupThrough: string
}

const Signup: React.FC = () => {
  const initialValues: InitialValues = {
    firstname: '',
    lastname: '',
    dob: '',
    // phone: '',
    email: '',
    // gender: '',
    signupThrough: 'web',
  }

  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [data, setData] = useState(initialValues)

  const [value, setValue] = useState<any>(null)
  const [dobValue, setDobValue] = useState<Date | any>(null)
  const [disableButton, setDisableButton] = useState(false)
  const [OTPScreen, setOTPScreen] = useState(false)
  const [code, setCode] = useState('')
  const [firstNameError, setFirstNameError] = useState(false)
  const [lastNameError, setLastNameError] = useState(false)
  const [dobError, setDobError] = useState(false)
  // const [genderError, setGenderError] = useState(false)
  // const [phoneError, setPhoneError] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const [otpError, setOtpError] = useState(false)
  const [errorType, setErrorType] = useState('')
  const [wrongNumber, setWrongNumber] = useState(false)
  const [userId, setUserId] = useState('')

  const { currentRole } = useAuth()
  console.log(dobValue, 'dob')

  const onContinue = async () => {
    let auth = false

    if (!data.firstname) {
      setFirstNameError(true)
      setErrorType('fieldError')
      auth = true
    } else {
      setFirstNameError(false)
    }
    if (!data.lastname) {
      setLastNameError(true)
      setErrorType('fieldError')
      auth = true
    } else {
      setLastNameError(false)
    }

    if (dobError) {
      auth = true
      setDobError(true)
      setErrorType('Incorrect-Dob')
    } else if (dobValue === '' || dobValue === null) {
      auth = true
      setValue(data.dob)
      setErrorType('Incorrect-Dob')
      setDobError(true)
    } else {
      const formattedDob = format(dobValue, 'yyyy-MM-dd')
      if (calculateDob(formattedDob) >= 18) {
        setValue(dobValue)
        setDobError(false)
      } else {
        auth = true
        setErrorType('under_18')
      }
    }
    // if (!data.gender) {
    //   setGenderError(true)
    //   setErrorType('GenderError')
    //   auth = true
    // } else {
    //   setGenderError(false)
    // }
    // if (data.phone.replace(/-/g, '').length < 10) {
    //   setPhoneError(true)
    //   setErrorType('Incorrect-phoneFormat')
    //   auth = true
    // } else {
    //   setPhoneError(false)
    // }

    if (!data.email) {
      setEmailError(true)
      setErrorType('fieldError')
    } else if (!EMAIL_REGEX.test(data.email.toLowerCase())) {
      setEmailError(true)
      setErrorType('Incorrect-email')
      auth = true
    } else {
      setEmailError(false)
    }

    if (!auth) {
      // setDisableButton(true)
      const formattedDob = format(dobValue, 'yyyy-MM-dd')
      setData({ ...data, dob: formattedDob })
      if (!wrongNumber) {
        let response: any = await axios
          .put(
            AppConstants.API_URL + '/auth/patient/onboard',
            {
              ...data,
              dob: formattedDob,
              email: data.email.toLowerCase(),
              // phone: '+1' + data.phone.replace(/-/g, ''),
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('userToken')}`,
                'x-platform-id': process.env.REACT_APP_PLATFORM_ID || '',
              },
            },
          )
          .then((res) => res)
          .catch((err) => err.response)

        if (response && response.data && response.data.code === 'success') {
          setErrorType('')
          localStorage.setItem(AppConstants.ACCESS_TOKEN, response?.data?.data?.accessToken)
          localStorage.setItem(AppConstants.CURRENTROLE_TOKEN, 'PATIENT')
          // setDisableButton(false)
          navigate('/choose-plan')
          setUserId(response?.data?.data?.id)
        } else if (response && response.data && response.data.data.code === 'user_already_exists') {
          setErrorType('userExists')
          // setDisableButton(false)
        } else if (response && response.data && response.data.data && response.data.data.code === 'email exists') {
          setErrorType('emailExists')
          // setDisableButton(false)
        } else if (
          response &&
          response.data &&
          response.data.data &&
          response.data.data.code === 'phone_number_already_exists'
        ) {
          setErrorType('mobileExists')
          // setDisableButton(false)
        } else if (
          response &&
          response.data &&
          response.data.data &&
          response.data.data.code >= 400 &&
          response.data.code <= 599
        ) {
          setErrorType('Error_Occurred')
          // setDisableButton(false)
        } else if (
          response &&
          response.data &&
          response.data.data &&
          response.data.data.statusCode >= 400 &&
          response.data.data.statusCode <= 599
        ) {
          setErrorType('Error_Occurred')
          // setDisableButton(false)
        } else {
          setErrorType('unknown')
          // setDisableButton(false)
        }
      } else if (wrongNumber) {
        // setDisableButton(true)
        const editedData = { ...data, dob: formattedDob, id: userId }
        let response: any = await axios
          .put(AppConstants.API_URL + '/auth/patient/onboard', editedData, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('userToken')}`,
              'x-platform-id': process.env.REACT_APP_PLATFORM_ID || '',
            },
          })
          .then((res) => res)
          .catch((err) => err.response)
        if (response && response.data.code === 'success') {
          setOTPScreen(true)
          // setDisableButton(false)
        }
      }
    }
  }

  const handleKeyDown = (e: any, nextFieldName: any) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      const nextField = e.target.form.elements[nextFieldName]
      if (nextField) {
        nextField.focus()
      }
    }
  }
  // useEffect(() => {
  //   analytics.page()
  // }, [])

  useEffect(() => {
    ReactGA.event('page_view', {
      page_title: 'Signup',
      page_location: '/signup',
    })
  }, [])

  useEffect(() => {
    dispatch(SetAvailablePlans([]))
    dispatch(SetSelectedPlan(null))
    dispatch(SetSelectedPlanInfo(null))
    dispatch(SetSelectedSlot(null))
  }, [])

  return (
    <div className={styles.container}>
      <Header
        headerText={!OTPScreen ? "Let's Create Your Profile" : 'Verify Phone Number'}
        showBackButton={true}
        onBack={() => {
          navigate('/')
        }}
      ></Header>
      {!OTPScreen && (
        <div className={styles.content}>
          <form
            onSubmit={(e) => {
              e.preventDefault()
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  autoFocus
                  sx={
                    firstNameError
                      ? {
                          backgroundImage: "url('images/error.svg')",
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'right 15px center',
                        }
                      : {
                          '& .MuiInputBase-input': {
                            textTransform: 'capitalize',
                          },
                        }
                  }
                  style={{ marginTop: '5px' }}
                  placeholder="Enter first name"
                  label="First Name"
                  name="firstname"
                  value={data.firstname}
                  error={firstNameError}
                  onChange={(e) => {
                    setData({ ...data, firstname: e.target.value })
                    if (data.firstname.length < 0) {
                      setFirstNameError(true)
                    } else {
                      setFirstNameError(false)
                    }
                  }}
                  onKeyDown={(e) => handleKeyDown(e, 'lastname')}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  sx={
                    lastNameError
                      ? {
                          backgroundImage: "url('images/error.svg')",
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'right 15px center',
                          textTransform: 'capitalize',
                        }
                      : {
                          '& .MuiInputBase-input': {
                            textTransform: 'capitalize',
                          },
                        }
                  }
                  placeholder="Enter last name"
                  value={data.lastname}
                  label="Last Name"
                  name="lastname"
                  error={lastNameError}
                  onChange={(e) => {
                    setData({ ...data, lastname: e.target.value })
                    if (data.lastname.length < 0) {
                      setLastNameError(true)
                    } else {
                      setLastNameError(false)
                    }
                  }}
                  onKeyDown={(e) => handleKeyDown(e, 'datefield')}
                />
              </Grid>
              <Grid item xs={12}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateField
                    name="datefield"
                    value={value}
                    sx={
                      dobError
                        ? {
                            backgroundImage: "url('images/error.svg')",
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'right 15px center',
                          }
                        : {}
                    }
                    onError={(error: any) => {
                      if (error) {
                        setDobError(true)
                      } else {
                        setDobError(false)
                      }
                    }}
                    onChange={(value: any) => {
                      setDobValue(value)
                      setDobError(false)
                    }}
                    label="Date of Birth"
                    disableFuture
                    onKeyDown={(e) => handleKeyDown(e, 'email')}
                  ></DateField>
                </LocalizationProvider>
              </Grid>
              {/* <Grid item xs={12}>
                <FormControl style={{ width: '100%' }}>
                  <InputLabel
                    id="gender-label"
                    sx={{
                      '@media (min-width: 600px)': {
                        marginTop: '-0.3rem',
                      },
                    }}
                  >
                    Gender
                  </InputLabel>
                  <Select
                    labelId="gender-label"
                    id="select-gender"
                    value={data.gender}
                    name="gender"
                    label="Gender"
                    error={genderError}
                    onChange={(e) => {
                      setGenderError(false)
                      setData({ ...data, gender: e.target.value })
                    }}
                    onKeyDown={(e) => handleKeyDown(e, 'email')}
                  >
                    <MenuItem value={'male'}>Male</MenuItem>
                    <MenuItem value={'female'}>Female</MenuItem>
                    <MenuItem value={'others'}>Other</MenuItem>
                  </Select>
                </FormControl>
              </Grid> */}
              <Grid
                item
                xs={12}
                sx={{
                  '@media (min-width: 600px)': {
                    marginTop: '0.3rem',
                  },
                }}
              >
                <TextField
                  type="email"
                  sx={
                    emailError
                      ? {
                          backgroundImage: "url('images/error.svg')",
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'right 15px center',
                        }
                      : {}
                  }
                  placeholder="Enter Email"
                  value={data.email}
                  label="Email"
                  name="email"
                  error={emailError}
                  onChange={(e) => {
                    setData({ ...data, email: e.target.value })
                    if (data.email.length < 0) {
                      setEmailError(true)
                    } else {
                      setEmailError(false)
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      onContinue()
                    }
                  }}
                />
              </Grid>
              {/* <Grid item xs={12}>
                <TextField
                  inputProps={{
                    inputMode: 'numeric',
                    pattern: '[0-9]*',
                    maxLength: 12,
                  }}
                  sx={
                    phoneError
                      ? {
                          backgroundImage: "url('images/error.svg')",
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'right 15px center',
                          textTransform: 'capitalize',
                        }
                      : {
                          '& .MuiInputBase-input': {
                            textTransform: 'capitalize',
                          },
                        }
                  }
                  placeholder="Enter Phone Number"
                  value={data.phone}
                  label="Phone Number"
                  name="phone"
                  error={phoneError}
                  onChange={(e) => {
                    const formattedValue = e.target.value
                      .replace(/\D/g, '')
                      .slice(0, 10)
                      .replace(/(\d{3})(\d{1,3})?(\d{0,4})?/, (_, first, second, third) => {
                        let result = first
                        if (second) result += '-' + second
                        if (third) result += '-' + third
                        return result
                      })
                    setPhoneError(false)
                    setData({ ...data, phone: formattedValue })
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      onContinue()
                    }
                  }}
                />
              </Grid> */}
              <Grid item xs={12}>
                {errorType && (
                  <div className={styles.errorContainer}>
                    <Typography sx={{ fontSize: '14px', fontWeight: 700 }}>
                      {errorType === 'fieldError' && `Please complete all fields above`}
                      {errorType === 'userExists' && `User already exists`}
                      {errorType === 'emailExists' && `Email already exists`}
                      {errorType === 'mobileExists' && `Mobile number already exists`}
                      {errorType === 'unknown' && `Unknown error`}
                      {errorType === 'Incorrect-phoneFormat' && `Invalid Mobile Number`}
                      {errorType === 'Incorrect-email' && `Invalid Email`}
                      {errorType === 'Incorrect-Dob' && `Invalid Date of Birth`}
                      {errorType === 'GenderError' && `Gender not selected`}
                      {errorType === 'Error_Occurred' && `Oops! something went wrong`}
                      {errorType === 'under_18' && `You must be 18 or older to sign up`}
                    </Typography>
                    <div
                      style={{
                        display: 'flex',
                        flex: '1',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        paddingRight: '12px',
                      }}
                    >
                      <img
                        onClick={() => {
                          setErrorType('')
                        }}
                        style={{ cursor: 'pointer' }}
                        src={'images/wrong.svg'}
                      ></img>
                    </div>
                  </div>
                )}
              </Grid>
            </Grid>
          </form>
        </div>
      )}
      {/* {OTPScreen && (
        <div className={styles.content}>
          <p className={styles.codeText}>
            Please enter the 6 digit code sent to <b className={styles.boldText}>{data.phone}</b> through SMS.
          </p>
          <form
            onSubmit={(e) => {
              e.preventDefault()
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  inputProps={{
                    inputMode: 'numeric',
                    pattern: '[0-9]*',
                  }}
                  autoFocus
                  sx={
                    otpError
                      ? {
                          backgroundImage: "url('images/error.svg')",
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'right 15px center',
                        }
                      : {}
                  }
                  placeholder="Enter Code"
                  label="Verification Code"
                  name="code"
                  error={otpError}
                  onChange={(e) => {
                    setCode(e.target.value)
                    setOtpError(false)
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      onVerify()
                    }
                  }}
                />
              </Grid>
            </Grid>
          </form>
          <div className={styles.nav}>
            <div>
              <span className={styles.codeText}>Didn’t receive a code?</span>
              <b
                className={styles.signUpText}
                onClick={() => {
                  ResendOTP()
                }}
              >
                Resend
              </b>
            </div>
            <div className={styles.wrongNumber} onClick={OnClickWrongNumber}>
              Wrong number?
            </div>
          </div>
          <Grid item xs={12}>
            {otpError && (
              <div className={styles.errorContainer}>
                <Typography sx={{ fontSize: '14px' }}>
                  {errorType === 'Empty' && `Please enter passcode`}
                  {errorType === 'Invalid' && `Invalid Code`}
                  {errorType === 'Code_Expired' && `Oops! code expired, Click Resend`}
                  {errorType === 'Error_Occurred' && `Oops! something went wrong`}
                </Typography>
                <div
                  style={{
                    display: 'flex',
                    flex: '1',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    paddingRight: '12px',
                  }}
                >
                  <img
                    onClick={() => {
                      setOtpError(false)
                    }}
                    style={{ cursor: 'pointer' }}
                    src={'images/wrong.svg'}
                  ></img>
                </div>
              </div>
            )}
          </Grid>
        </div>
      )} */}
      <Footer
        buttonText={'Continue'}
        isLoading={false}
        shouldShowFive={true}
        trackerIndex={1}
        isDisabled={data.firstname === '' || data.lastname === '' || data.email === '' || dobValue === null}
        buttonId={'sign-in-button'}
        buttonType={'submit'}
        onButtonClick={() => {
          data.firstname === '' || data.lastname === '' || data.email === '' || dobValue === null
            ? undefined
            : onContinue()
        }}
      ></Footer>
    </div>
  )
}

export default Signup
